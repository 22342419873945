@import 'src/sass/_variables.scss';
.v3-container{
    margin: 0 auto;
    width: 100%;
    max-width: $screen-size-desktop;
    // background: red;
    &.large{
        max-width: 800px;
    }
    &.small{
        max-width: 460px;
    }
    &.xsmall{
        max-width: 280px;
    }
    &.medium{
        max-width: 800px;
    }
    &.hasPadding{
        padding: 0;
        @media screen and (max-width: $screen-size-desktop) {
            padding: 0px 8px;
        }
    }
}