.survey{
  display: flex;
  flex-direction: column;
  padding: 16px 0;
  border-top: 1px solid $color-grey-light;
  border-bottom: 1px solid $color-grey-light;
  margin: 16px auto;
  width: 100%;
  .survey-topline{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 16px;
    survey-title{
      font-size: 18px;
      font-weight: 600;
      text-align: center;
      color: $color-text-default;
    }
    .survey-name{
      margin-top: 8px;
      color: $color-text-medium-2;
    }
  }
  .survey-body{
    .survey-vote{
      display: flex;
      flex-direction: column;
      padding: 8px 0;
      .survey-vote-infos{
        display: flex;
        justify-content: space-between;
        .survey-vote-name{
          font-size: 15px;
          margin-right: 16px;
          color: $color-text-default;
        }
        .survey-vote-stat{
          font-size: 15px;
          margin-left: 16px;
          color: $color-text-medium-2;
          white-space: nowrap;
          .icon-check-circle{
            width: 10px;
            margin-right: 8px;
          }
        }
      }
    }
    .survey-bar-container{
      width: 100%;
      height: 10px;
      border-radius: 2px;
      background-color: $color-grey-light;
      margin-top: 4px;
      overflow: hidden;
      .survey-bar-progression{
        width: 0;
        height: 100%;
        background-color: $color-primary;
        transition: width .2s linear;
      }
    }
    .survey-more-options{
      font-size: 12px;
      color: $color-primary;
      cursor: pointer;
    }
  }
  .survey-baseline{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 16px;
  }
}