#encourage-signup {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
    font-family: 'Poppins', sans-serif;
    line-height: 20px;

    animation: open-signup .7s ;

    @keyframes open-signup {
        0%    { opacity: 0; }
        100%  { opacity: 1; }
    }
    @keyframes open-signup-window {
        0%    { transform: scale(0.7); }
        100%  { transform: scale(1); }
    }

    .window{
        animation: open-signup-window .5s ease-in;
    }


    /* Common */
    .center{
        height: 100%;
    }
    & {
        .full-bg {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }

        .container-signup {
            display: flex;
            flex-direction: column;
            max-height: 100vh;
            overflow-y: auto;

            .header {
                color: white;

                .header-text {
                    .logo {
                        background-image: url('./images/logo-voissa.png');
                        width: 134px;
                        height: 28px;
                    }
                }

            }
        }

        .form {
            h2 {
                text-align: center;
                font-weight: 600;
                font-size: 18px;
            }

            ul.arguments {
                list-style: none;
                margin: 0;
                padding: 0;
    
                li {
                    background-color: #f3f3f3;
                    border-radius: 8px;
                    padding: 12px 8px;
                    box-sizing: border-box;
                    font-weight: 500;
                    font-size: 12px;
                    display: flex;
                    align-items: center;
                    margin-bottom: 8px;
    
                    .icon {
                        /*width: 32px;
                        text-align: center;
                        margin-left: -6px;
                        margin-right: 2px;*/
                        padding: 0 8px;

                        img {
                            height: 18px;
                        }
                    }
                    label {
                        flex: 1;
                    }
                }
            }      

            .cta-list {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 32px;

                .signup-cta {
                    background-color: #2072ef;
                    color: white;
                    text-align: center;
                    padding: 8px 64px;
                    box-sizing: border-box;
                    border-radius: 8px;
                    border: none;
                    outline: none;
                    cursor: pointer;
                    font-family: 'Poppins';

                    .line1 {
                        font-weight: 600;
                        font-size: 14px;
                        text-transform: uppercase;
                    }
                    .line2 {
                        font-size: 12px;
                        font-weight: 200;
                    }
                }

                .signin-cta {
                    color: #4d4d4d;
                    font-size: 12px;
                    margin-top: 8px;
                    font-weight: 600;
                    text-decoration: underline;
                }
            }            
        }

        .text {
            margin: 16px;
            font-size: 11px;
            color: black;
            line-height: 20px;
        }

  
    }

    /* Mobile */
    @media only screen and (max-width: 889px) {
        .close-desktop{
            display: none;
        }
        .full-bg {
            background-color: #27131e;
            opacity: .7;
            filter: blur(4px);
        }
        .center {
            position: relative;
            display: flex;
            width: 100%;
            height: 100%;
            justify-content: center;
            align-items: center;
        }
        .container-signup {
            background-color: #eaeaea;
            margin: 0 16px;
            border-radius: 16px;

            .header {
                background-image: url("./images/header-bg.jpg"); /* truc rose */
                background-repeat: no-repeat;
                background-size: cover;
                padding-bottom: 100px;
                position: relative;

                .header-text {
                    .logo {
                        margin: 16px auto 0 auto;
                    }
                    .subtitle {
                        margin: 8px auto 16px auto;
                        text-align: center;
                    }
                }

                .close-mobile {
                    position: absolute;
                    top: 8px;
                    right: 8px;
                    width: 24px;
                    .icon-close-x{
                        width: 100%;
                        fill: #FFFFFF;
                        cursor: pointer;
                    }
                }

            }

            .window-wrapper {
                flex: 1;
                display: flex;
                justify-content: center;
                align-items: center;

                .form {
                    margin: -100px 16px 0 16px;
                    position: relative;
                    background-color: white;
                    color: #3d3d3d;
                    padding: 16px;
                    border-radius: 16px;
                    box-sizing: border-box;

                    h2 {
                        margin: 0 0 10px 0;
                    }

                    ul.arguments {
                        padding: 0;
                    }
                }
            }
        }
    }

    /* Desktop */
    @media only screen and (min-width: 890px) {
        .close-mobile{
            display: none;
        }
        .full-bg {
            background-image: url("./images/desktop-bg.jpg");
            background-size: cover;
        }
        .container-signup {
            height: 100%;
            
            .header {
                position: relative;

                .header-text {
                    display: flex;
                    align-items: center;
                    padding: 32px 0;
                    margin-left: 80px;
                    @media (max-width: 1080px) {
                        margin-left: 16px;
                    }

                    .subtitle {
                        font-size: 22px;
                        margin-left: 32px;
                        position: relative;

                        &::before {
                            content: "";
                            width: 1px;
                            height: 20px;
                            background-color: white;
                            position: absolute;
                            left: -16px;
                            top: 8px;
                            opacity: 0.5;
                        }
                    }
                }
            }

            .window-wrapper {
                flex: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                padding-bottom: 100px;

                .window {
                    position: relative;
                    display: flex;
                    width: 842px;
                    height: 527px;
                    border-radius: 16px;
                    overflow: hidden;
                    background-color: white;
                    box-shadow: 4px 12px 20px 4px #00000042;

                    .close-desktop {
                        position: absolute;
                        top: 16px;
                        right: 16px;
                        width: 24px;
                        z-index: 2;
                        .icon-close-x{
                            width: 100%;
                            fill: #000000;
                            cursor: pointer;
                        }
                    }

                    .illustration {
                        height: 100%;
                        background-image: url("./images/illustration800.jpg");
                        background-size: cover;
                        background-position: center;
                        flex: 1;
                    }

                    .main-section {
                        flex: 1;

                        .form {
                            position: relative;
                            color: #3d3d3d;
                            padding: 16px;
                            box-sizing: border-box;
                            
                            h2 {
                                margin: 24px 0 32px
                            }
   
                            ul.arguments {
                                padding: 0 16px;
                            }
                        }
    
                        .text {
                            margin-top: 64px;
                        }
                    }
                }
            }
        }        
    }

}
