.symbols {
  .gender {
    font-size: 1em;
    margin: 0 8px;

    .fa-venus {
      color: $color-main;
    }

    .fa-mars {
      color: $color-primary;
    }

    .group-icon-trav {
      position: relative;

      .fa-venus {
        position: absolute;
        left: 0;
        top: 4px;
      }
    }
  }
}
