#webcam{
  h1{
    font-size: 1.4em;
  }
  .main{
    display: flex;
    flex-direction: row;
    margin-top: 16px;
    h2{
      margin: 8px 0 8px 0;
      font-size: .85em;
      line-height: 1.3em;
      color: #2a2a2a;
      display: inline-block;
      a{
        font-size: 1em;
        font-weight: bold;
      }
    }
    .col{
      display: flex;
      flex:1;
      flex-direction: column;
      //width: 1OO%;
    }
    .sidebar{
      margin-left: 16px;
      max-width: 20%;
      padding: 8px 8px 8px 16px;
      border-left: 1px solid #f1f1f1;
      .cat-links-col{
        margin-top: 16px;
        &:first-child{
          margin-top: 0px;
        }
        .title{
          background: darken($color-secondary, 8);
          color: #FFF;
          padding: 12px;
          font-weight: 500;
          border-radius: 4px;
          font-size: 1em;
          //text-shadow: 1px 1px 3px darken($color-secondary, 15);
        }
        ul{
          margin: 8px 0;
          li{
            //list-style-type: initial;
            padding: 4px 0;
            font-size: .8em;
            a{
              color: $color-primary;
            }
            &:before {
              content: "•"; /* Insert content that looks like bullets */
              padding-right: 8px;
              //color: black; /* Or a color you prefer */
            }
          }
        }
      }
    }
    .explanations{
      display: flex;
      flex-direction: row;
      margin: 8px 0;
      .explanation-col{
        padding: 8px;
        flex: auto;
        .explanation-title{
          font-weight: bold;
          font-size: .9em;
          text-decoration: underline;
          margin-bottom: 8px;
        }
        ul{
          li{
            //list-style-type: initial;
            padding: 4px 0;
            font-size: .8em;
            a{
              color: $color-primary;
            }
            &:before {
              content: "•"; /* Insert content that looks like bullets */
              padding-right: 8px;
              //color: black; /* Or a color you prefer */
            }
          }
        }
        &:first-child{
          padding-left: 0;
        }
        &:last-child{
          padding-right: 0;
        }
        //width: calc(100%/3);
      }
    }
  }
  .description{
    margin-top: 16px;
    font-size: .8em;
    line-height: 1.3em;
    color: #525252;
    p{
      margin: 12px 0;
    }
    h2{
      display: block;
      background: #e5e5e5;
      padding: 8px;
      border-radius: 4px;
      text-align: center;
      color: #000000;
      font-weight: initial;
    }
  }

  @media screen and (max-width: $screen-size-small) {
    .main{
      flex-direction: column;
      .sidebar{
        margin-left: 0px;
        padding-left: 0px;
        border-left: none;
        max-width: none;
        margin-top: 20px;
      }
      .explanations{
        flex-direction: column;
        .explanation-col{
          padding: 0;
          margin: 8px 0;
        }
      }
    }
  }
} 