.edit-comment {
  width: 100vw;
  height: 100vh;
  border-radius: 0;
  .modal-body {
    .custom-textarea {
      height: 120px;
      margin: 16px 0 0 0;
      width: 100%;

      textarea {
        resize: vertical;
      }
    }
  }
}