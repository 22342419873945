#user-by-ip {
  max-height: 100vh;
  padding: 8px 0;
  display: flex;
  flex: 1;
  flex-direction: column;

  .card {
    display: flex;
    flex: 1;
    //flex-wrap: wrap;
    min-width: 450px;
    flex-direction: column;
    margin-top: 0px;
    max-height: calc(100vh - 16px);
    padding: 0;
    width: 100%;
    //max-height: 100vh;
  }

  .topline {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 16px;
    border-bottom: 1px solid $color-grey-light;
    font-size: 15px;


    .icon-close {
      cursor: pointer;
    }
  }

  .wrapper {
    display: flex;
    flex: 1;
    overflow-y: scroll;
    flex-direction: column;
    //overflow-y: scroll;
    //max-height: 350px;
    padding: 8px;
    width: 100%;
    //max-width: 500px;
  }

  .recent-activity {

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 0;
    border-bottom: 1px solid $color-grey-light;

    a {
      font-size: 13px;
      color: $color-primary;
      text-decoration: none;
      margin-right: 16px;

      .avatar {
        width: 32px;
        height: 32px;
      }
    }

    &:last-child {
      padding-bottom: 0;
      border-bottom: none;
    }

    .user-infos {
      a, a:focus {
        color: $color-primary;
        text-decoration: none;
      }

      .desc {
        font-size: 14px;
      }

      .timer {
        display: flex;
        align-items: center;
        font-size: 12px;
        color: $color-text-grey-medium;
        padding: 4px 0;

        i {
          padding-right: 4px;
        }
      }

      a {
        font-size: 13px;
        color: $color-primary;
        text-decoration: none;
      }
    }

    img {
      width: 48px;
      height: 48px;
      border-radius: 48px;
    }
  }
}

@media screen and (max-width: $screen-size-medium) {
  #user-by-ip {
    padding: 0px;

    .card {
      width: 100%;
      min-width: auto;
    }
  }
}