.mk-editor{
  display: flex;
  flex-direction: column;
  border: 1px solid $color-grey-light;
  border-radius: $border-radius;

  img, video{
    max-width: 100%;
    max-height: 1000px;
  }

  .draft-uploaded-media{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 12px 0;
    img, video{
      max-width: 100%;
      width: auto;
      float: left;
      border-radius: 16px;
      border: 1px solid $v3-color-bg-light-grey;
      overflow: hidden;
      max-height: 1000px;
    }
    
  }

  .draft-shared-media{
    display: flex;
    flex-direction: column;
    max-width: 350px;
    padding: 16px;
    text-decoration: none;
    border: 1px solid $color-grey-light;
    border-radius: $border-radius;
    .content{
      overflow: hidden;
      max-width: 100%;
      max-height: 200px;
      min-height: 200px;
      margin-bottom: 8px;
      overflow: hidden;
      border-radius: $border-radius;
      background-color: $color-grey-light;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      img{
        max-width: 100%;
        max-height: 100%;
        margin: 0 auto;
      }
    }
    .video{
      display: flex;
      justify-content: center;
      align-items: center;
      .btn-play{
        position: absolute;
        height: 48px;
        width: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50px;
        color: #e3e3e3;
        background: rgba(0, 0, 0, 0.85);
      }
    }
    .caption{
      border-top: 1px solid $color-grey-light;
      padding-top: 8px;
      font-weight: 600;
      @extend .break-long-text;
      color: $color-text-default;
    }
    &.article, &.album{
      .content{
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        flex-grow: 1;
      }
    }
  }

  .link{
    color: $color-primary;
  }

  span[data-text]{
    @extend .break-long-text;
    font-family: $font-for-readability;
  }

  .emojiSelectPopoverGroups{
    width: unset;
  }
  .emojiSelectPopoverGroupItem{
    flex: 0 0 calc(100% / 7);
  }
  .mk-editor-toolbar{
    display: flex;
    flex-wrap: wrap;
    position: relative;
    border-bottom: 1px solid $color-grey-light;
    padding: 8px 0px;
    .mk-editor-btn-group{
      padding: 8px 16px;
      border-right: 1px solid $color-grey-light;
      align-items: center;
      display: flex;
      &:last-child{
        margin-right: 0;
        border-right: 0;
      }
    }
    .mk-editor-btn{
      color: $color-text-grey-medium;
      cursor: pointer;
      margin-right: 16px;
      display: inline-block;
      padding: 4px 8px;
      user-select: none;
      &.dropdown-toggle{
        .icon-arrow-down{
          margin-left: 4px;
          transition: transform .1s linear;
        }
        &.active{
          .icon-arrow-down{
            transform: rotate(180deg);
            fill: $color-primary;
          }
        }
      }
      &.active{
        color: $color-primary;
      }
      &:last-child{
        margin-right: 0;
      }
    }
    .dropdown-menu{
      position: absolute;
      border: 1px solid $color-grey-light;
      border-radius: $border-radius;
      background-color: #FFF;
      z-index: 2;
      top: 50px;
      .dropdown-item{
        display: block;
        padding: 8px;
        color: $color-text-default;
        h1{
          font-size: 2em;
        }
        h2{
          font-size: 1.5em;
        }
        h3{
          font-size: 1.17em;
        }
        h4{
          font-size: 1em;
        }
        h5{
          font-size: 0.83em;
        }
        h6{
          font-size: 0.67em;
        }
      }
    }
  }
  .DraftEditor-root{
    .public-DraftEditorPlaceholder-root{
      padding: 8px;
    }
    .public-DraftEditor-content{
      max-height: 500px;
      overflow: auto;
      min-height: 200px;
      padding: 8px;
      h1{
        font-size: 2em;
      }
      h2{
        font-size: 1.5em;
      }
      h3{
        font-size: 1.17em;
      }
      h4{
        font-size: 1em;
      }
      h5{
        font-size: 0.83em;
      }
      h6{
        font-size: 0.67em;
      }
      div[data-contents]{
        line-height: 1.4;
        color: #333333;
        font-size: 14px;
        white-space: pre-wrap;
        font-weight: 400;
        font-family: $font-for-readability;
      }
    }
  }
  &.simple-display{
    display: flex;
    flex-direction: row;
    width: 100%;
    .DraftEditor-root {
      width: 100%;
      .public-DraftEditor-content {
        min-height: unset;
        width: 100%;
        height: 36px;
        overflow: auto;
      }
    }
    &.edit-comment--draft{
      position: relative;
      .DraftEditor-root {
        .public-DraftEditor-content {
          min-height: unset;
          width: 100%;
          height: 150px;
          overflow: auto;
        }
      }
      .emojiSelect{
        display: flex;
        align-items: flex-end;
        margin: 8px 8px 8px 0;
        .emojiSelect, .emojiSelectPopover{
          display: flex;
          flex-direction: column;
          right: 0;
          bottom: 0;
          height: 100%;
          .emojiSelectPopoverGroups{
            height: unset;
            flex: 1;
          }
        }
      }
    }
    &.add-comment{
      border-radius: 25px;
      padding: 0px;
      overflow: hidden;
      .emojiSelect{
        display: flex;
        align-items: flex-end;
        .emojiSelectButton, .emojiSelectButtonPressed{
          border: 0;
          width: 100%;
        }
      }
      .emojiSelectPopover{
        margin-top: -40px;
        box-shadow: unset;
        right: 0;
      }
      .DraftEditor-root {
        padding: 0px;
        overflow: hidden;
        display: flex;
        align-items: center;
        .public-DraftEditorPlaceholder-root{
          padding: 8px;
          line-height: 1.3;
        }
        .DraftEditor-editorContainer{
          width: 100%;
          padding: 8px;
        }
        .public-DraftEditor-content {
          padding: 0;
          height: 100%;
          max-height: 72px;
          div[data-contents]{
            line-height: 1.3;
          }
        }
      }
    }
    &.add-status{
      border: 0;
      padding: 0 8px;
      font-size: 14px;
      .DraftEditor-root {
        .public-DraftEditor-content{
          min-height: unset;
          height: 70px;
          padding: 0;
        }
        .public-DraftEditorPlaceholder-root{
          padding: 0;
        }
      }
    }
  }
  &.waiting-draft{
    position: relative;
    .fake-answer-topic{
      background-color: rgba(0,0,0, .7);
      border-radius: $border-radius;
      border: 1px solid $color-grey-light;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 5;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}