.ignored-user-wrapper {
  width: 100%;

  .wrapper-profil-ignored-user {
    width: 100%;
    height: 100%;

    .main-wrapper {
      width: 100%;
      height: 100%;
      background-color: #FFF;
      padding: 8px;
      border-radius: $border-radius;
      padding: 0 !important;

      .title {
        padding: 8px 0;
        font-weight: 700;
        font-size: 18px;
        width: 100%;
        margin: 8px 0;
        color: $color-text-default;
      }

      h4 {
        width: 100%;
        margin: 8px 0;
        font-size: 14px;
        color: $color-text-medium-2;
      }

      .searchbar {
        width: 100%;
        border: 1px solid $color-grey-light;
        margin: 0 0 8px 0;

        input {
          width: 100%;
          overflow: hidden;
          padding: 4px 8px;

          &:focus {
            outline: none;
          }
        }
      }

      .list {
        display: flex;
        height: auto;
        overflow: visible;
        flex-wrap: wrap;
        padding: 0;
        width: 100%;

        .ignored-user {
          display: flex;
          align-items: center;
          margin: 8px;
          margin-bottom: 0;
          padding-bottom: 16px;
          width: calc((100% / 2) - 16px);
          border: 0px solid transparent;

          .avatar {
            width: 45px;
            height: 45px;
          }

          .username {
            margin-right: 8px;
            word-break: break-word;
            word-wrap: break-word;
          }

          .infos-user-wrapper {
            margin-left: 16px;
          }
        }

        .btn-group {
          margin-left: auto;
        }
      }
    }
  }
}

@media screen and (max-width: $screen-size-medium) {
  .ignored-user-wrapper {
    .wrapper-profil-ignored-user {
      .main-wrapper {
        .list {
          .ignored-user {
            width: 100%;
          }
        }
      }
    }
  }
}
