#welcomeModeration {
  display: flex;
  flex-direction: column;
  max-width: 700px;
  margin: 0 auto;

  h3 {
    font-size: 20px;
    text-align: center;
    margin: 16px;
  }

  h4 {
    margin: 8px;
    text-transform: uppercase;
    color: $color-text-default;
  }

  ul {
    margin: 8px;

    li {
      margin: 4px;
      color: $color-text-default;
      line-height: 18px;
      font-size: 15px;
      letter-spacing: .02em;
    }
  }

  p {
    margin: 8px;
    color: $color-text-default;
    line-height: 18px;
    font-size: 15px;
  }
}
