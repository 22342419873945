#list-reportings {

  .sub-controls-row {
    justify-content: space-between;
    margin-bottom: 16px;
    .w-30{
      width: 30%;
    }
    .button-group{
      .active{
        cursor: default;
      }
    }
  }

  .table thead{
    background-color: white;
    font-weight: bold;
    th{
      color: #4A4A4A;
      .active{
        fill : #42a5f5;
      }
    }
  }

  .pagination {
    margin-top: 16px;
  }
}