

#uploads {
  flex: 1;
  padding: $v3-padding-drawer;

  .uploads-header{
    display: flex;
  }

  .uploads-footer{
    display: flex;
    justify-content: flex-end;

  }
  
.img-preview-container{
    margin: 0 auto 16px auto;
}

.modal-body{
    display: flex;
    flex-direction: column;
}

.upload-input-btn{
    display: block;
    margin: 0 auto;
}
}