.survey-edit{
  .modal-topline{
    .question-label{
      display: block;
      font-size: 15px;
      margin-bottom: 8px;
    }
  }
  .modal-body{
    .custom-radio-input{
      padding: 8px 0;
      width: 100%;
    }
  }
}