#survey {
  background-color: #FFF;
  border-radius: $border-radius;
  width: 100%;
  padding: 16px;
  color: $color-text-default;

  .icon-close {
    position: static;
    fill: $color-red;
    margin-left: 16px;
    cursor: pointer;
    width: 12px;
  }

  .survey-topline {
    background-color: #FFF;
    border-bottom: 1px solid $color-grey-light;
    padding-bottom: 16px;
  }

  .survey-content {
    padding: 16px;
    padding-left: 32px;
    max-height: 475px;
    overflow: auto;

    .survey-input {
      width: 100%;
      margin: 8px 0;
    }

    .survey-input-checkbox {
      width: auto;
      margin-right: 4px;
    }

    .survey-sub-topline {
      border-bottom: 0;
      width: 100%;
      padding: 0;
    }

    .survey-question-group {
      width: 100%;
      margin: 16px 0;

      .survey-choice-group {
        display: flex;
        align-items: center;

        span {
          font-size: 14px;
          font-weight: 700;
          margin-right: 16px;
        }
      }
    }

    .survey-add-choice {
      margin: 8px 0;
      display: flex;
      align-items: center;
      cursor: pointer;
      color: $color-primary;
      font-size: 14px;

      i {
        fill: $color-primary;
        width: 16px;
        margin-right: 8px;
      }
    }

    .survey-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin: 8px 0;
      font-weight: 700;
      font-size: 14px;
      color: $color-text-default;
    }

    .allow-multiple-answer {
      label {
        font-size: 14px;
        color: $color-text-medium-2;
        font-weight: normal;
      }
    }
  }

  .survey-baseline {
    display: flex;
    align-items: center;
    padding: 16px 16px 0 16px;
    border-top: 1px solid $color-grey-light;

    span {
      margin: 0 8px;
      font-size: 12px;
      color: $color-text-medium-2;
    }

    .btn {
      margin: 0 8px;
    }
  }
}

@media screen and (max-width: $screen-size-medium) {
  #survey {
    height: 100%;
    padding: 8px;
    display: flex;
    flex-direction: column;

    .survey-content {
      padding: 8px;
    }

    .survey-baseline {
      margin-top: auto;
      padding: 16px 0 0 0;
    }
  }
}

@media screen and (max-width: $screen-size-small) {
  #survey {
    .survey-baseline {
      flex-direction: column;

      div, span {
        margin: 8px 0;
        flex-shrink: 0;
        text-align: center;
      }

      span {
        border: 1px solid $color-grey-light;
        padding: 8px;
      }
    }
  }
}
