.tag-list {
  margin: -3px 0px;

  .tag {
    display: inline-flex;
    align-items: center;
    color: $color-primary;
    font-size: 11px;
    border-radius: 120px;
    padding: 4px 12px;
    border: 1px solid lighten($color-primary, 30);
    background: lighten($color-primary, 45);
    margin: 3px 0px;
    margin-right: 6px;
    transition: background 0.3s;

    &:hover {
      background: lighten($color-primary, 40);
    }

    &:last-child {
      margin-right: 0px;
    }

    a {
      text-decoration: none;
      color: $color-primary;
    }
  }

  .icon-close {
    fill: $color-red;
    width: 8px;
    margin-left: 8px;
    cursor: pointer;
  }

  .icon-plus {
    fill: $color-green;
    width: 10px;
    margin-left: 8px;
    cursor: pointer;
  }
}
