.forum-card {
  .username {
    font-weight: 500;
    color: #000;
    opacity: .8;
  }

  .pagination-wrapper {
    padding: 16px;
  }

  .card {
    padding: 0;
    border-radius: 8px;
  }

  .last-message{
    a{
      color : $color-primary;
    }
  }

  .warning {
    text-align: center;
    padding: 16px 8px;
    font-size: 16px;
  }

  .global-informations {
    display: flex;
    margin: 8px 8px;

    .global-informations-total {
      font-size: 14px;
      color: $color-text-medium-2;
      padding: 16px;



      .important-value {
        font-size: 16px;
        color: $color-main;
        font-weight: 700;
      }
    }
  }

  .forum-card-user-list {
    margin: 8px;
    font-size: 14px;
    color: $color-main;
    line-height: 15px;
    padding: 0px 16px 32px 16px;

    .forum-card-topline {
      background-color: transparent;
      font-size: 14px;
      color: $color-text-medium-2;
      padding-left: 4px;
      padding-right: 4px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .forum-card-user-rank {
        display: flex;
        a, span {
          padding: 8px 16px;
          color: $color-primary;
          cursor: pointer;
          text-decoration: none;

          &:last-child {
            padding-right: 0;
          }
        }
      }
    }
  }

  .hidden {
    display: none !important;
  }


  .topline-bottom {
    border-radius: 8px 8px 0 0;
    padding: 16px;
    font-size: 18px;
    font-weight: bold;

    &.subcategory {
      padding-bottom: 0;
    }

    .title-wrapper {
      display: flex;
      flex-direction: row;

      a, a:focus {
        color: #000;
        text-decoration: none;
      }

      &.subcategory {
        padding-bottom: 16px;
        border-bottom: 1px solid #f6f6f6;
      }

      .title-category {
        padding-right: 4px;
        color: #FFFFFF;
        // display: flex;
        display: inline;

        span + span:before {
          content: " · ";
          padding: 0;
          font-size: 20px;
        }

        span + div:before {
          content: " · ";
          padding: 0;
          font-size: 20px;
        }
      }

      .title-subcategory {
        padding: 0 4px;
        color: $color-primary;
      }

      .title-topic {
        color: $color-main !important;
      }
    }
  }

  .wrapper-settings {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 16px;

    .wrapper-btn {
      display: flex;
      align-items: center;

      .forum-btn-settings {
        margin-right: 8px;
      }

      .button-group {
        margin: 0 8px;
      }

      .dropdown-menu {
        &.forum-filter {
          width: 250px;
          font-weight: normal;
        }

        .menu-item {
          &.active {
            color: $color-primary;
          }
        }
      }

      .btn-rounded-corner {
        text-align: center;

        &.start-topic {
          margin: 0 8px;

          i {
            width: 10px;
            height: 10px;
          }
        }

        //&:last-child{
        //  margin-right: 0;
        //}
        .fa {
          padding-right: 4px;
        }
      }
    }

    .hidden {
      display: none;
    }

    &.hidden {
      display: none;
    }

    .pagination {
      &:first-child {
        padding: 0;
      }
    }
  }
}

@media screen and (max-width: $screen-size-medium) {
  .forums-subcategory {
    .container {
      padding: 0;

      .forum-card {
        .wrapper-settings {
          .pagination {
            width: 100%;
            margin: 0 8px;
          }
        }

        .card {
          margin-top: 0;

          .topline {
            border-radius: 0;
          }

          .card-subcategory {
            position: relative;

            .wrapper-left {
              width: 100%;

              .wrapper-card-subcategory {
                flex-direction: column;

                .wrapper-title {
                  .before-title {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .icon-cog {
                      position: absolute;
                      top: 8px;
                      right: 8px;
                    }
                  }
                  .title {
                    flex-direction: column;
                    align-items: flex-start;
                    .title-link{
                      font-size: 18px;
                    }
                    .row {
                      flex-wrap: wrap;
                    }

                    a {
                      margin-top: 8px;
                      font-size: 18px;
                    }
                  }

                  .started-by {
                    width: 100%;
                    flex-wrap: wrap;
                  }

                  .pagination {
                    margin: 8px 0;
                  }

                  .btn-pagination-responsive {
                    width: 100%;
                  }
                }

                .wrapper-infos {
                  display: none;
                  align-items: center;
                  justify-content: space-between;
                  width: 100%;

                  .subjects {
                    display: flex;
                  }
                }
              }
            }

            .last-post {
              padding: 0;
              margin-top: 8px;
              padding: 8px 16px;
              margin-right: 16px;
              overflow: hidden;
            }
          }
        }
      }
    }
  }
  .forum-card {
    .topline {
      &.subcategory {
        .title-wrapper {
          .title-category {
            margin-bottom: 16px;
          }
        }

        .wrapper-settings {
          .wrapper-btn {
            width: 100%;
            //justify-content: flex-end;
            .dropdown-menu {
              &.forum-filter {
                width: 100%;
              }
            }
          }
        }
      }
    }

    .global-informations {
      flex-wrap: wrap;
    }

    .forum-card-user-list {
      .forum-card-topline {
        flex-wrap: wrap;

        .forum-card-user-rank {
          flex-wrap: wrap;

          a {
            width: 100%;
            padding: 8px 0;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $screen-size-small) {
  .forum-card {
    .card {
      border-radius: 0;

      .topline {
        border-radius: 0;
      }
    }
  }
  .forums-subcategory {
    .container {
      .forum-card {
        .card {
          .topline {
            &.subcategory {
              .title-wrapper {
                flex-direction: column;
              }
            }
          }

          .card-subcategory {
            flex-wrap: wrap;
            align-items: flex-start;
            border-bottom: 1px solid $color-grey-light;

            .wrapper-left {
              width: 50%;
              align-items: flex-start;
              margin-right: 8px;

              .wrapper-card-subcategory {
                flex-direction: column;

                .wrapper-title {
                  flex-direction: column;
                  width: 100%;

                  .before-title {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                  }

                  .title {
                    flex-direction: column;
                    align-items: flex-start;
                    font-size: 14px;

                    .row {
                      flex-wrap: wrap;
                    }

                    a {
                      margin-top: 0px;
                    }
                  }

                  .started-by {
                    //display: none;
                    flex-wrap: wrap;
                  }

                  .pagination {
                    margin: 8px 0;
                  }

                  .btn-pagination-responsive {
                    width: 100%;
                    border: 0;
                    padding: 4px 16px 0 0;
                    border-radius: 0;
                  }
                }

                .wrapper-infos {
                  display: none;
                  align-items: center;
                  justify-content: space-between;
                  width: 100%;

                  .subjects {
                    display: flex;
                  }
                }
              }
            }

            .last-post {
              width: 50%;
              margin-top: 0;
              padding-top: 0;
              margin-right: 0;
              padding-left: 8px;
              padding-right: 0;
              align-self: flex-start;

              .avatar {
                width: 32px;
                height: 32px;
              }

              .wrapper-infos-last {
                padding-left: 8px;
              }
            }
          }
        }

        .wrapper-settings {
          flex-wrap: wrap;

          .wrapper-btn {
            .dropdown {
              margin-left: 8px;
            }

            .small {
              margin: 4px 0;
              width: 100%;
            }
          }
        }
      }
    }
  }
}
