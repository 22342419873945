#deletedContent {
  .block {
    .topline {
      width: 100%;
      font-weight: 400;
      padding: 16px 8px;
      background-color: #f9ddf9;
      border-radius: 5px;
      color: $color-text-default;
    }

    .icon-clock-warn {
      width: 18px;
      height: 18px;
      margin-right: 4px;
    }

    .content {
      line-height: 18px;
    }
  }
}
