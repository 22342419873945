
#list-type-messages{
  display: flex;
  align-items: center;
  .options-messages{
    flex: 1;
    margin-right: 12px;
  }
  .cta-copy{
    cursor: pointer;
    width: 100px;
    svg{
      margin-right: 8px;
    }
  }
}