.card {
  background: #FFF;
  padding: 8px;
  // border-radius: $border-radius;
  margin: 16px 0px 0px 0px;
  // box-shadow: $box-shadow;

  &.big {
    padding: 16px;

    h2, h3 {
      display: flex;
      align-items: center;

      a {
        color: $color-primary;
        text-decoration: none;
        font-weight: normal;
        font-size: 15px;
      }
    }

    h3 {
      font-size: 14px;
    }
  }
}
