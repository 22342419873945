.group{
    display: inline-flex;
    align-items: flex-start;
    flex-wrap: wrap;
    &.center{
        justify-content: center;
    }
    &.full{
        width: 100%;
    }
    &.split{
        display: flex;
        justify-content: space-between;
    }
    &.right{
        width: 100%;
        justify-content: flex-end;
    }
    &.grid{
        margin-bottom: -8px;
        > *{
            margin-bottom: 8px;
        }
    }
    > *{
        margin-right: 8px;
        &:last-child{
            margin-right: 0;
        }
    }
    
}