.friends-wrapper {
  .friends {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid $color-grey-light;

    &:last-child {
      border-bottom: none;
    }

    .avatar {
      width: 32px;
      height: 32px;
    }

    a, a:focus {
      color: $color-primary;
      text-decoration: none;
    }

    .infos-user-wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      color: $color-text-default;
      padding-left: 8px;
      flex-grow: 1;

      a {
        display: flex;

        .username {
          font-size: 14px;
          font-weight: 600;
          padding-bottom: 4px;
          word-break: break-all;
        }
      }

      .timer {
        font-size: 12px;
        color: $color-text-grey-medium;
        width: 100%;
        display: flex;
        align-items: center;

        &.offline {
          &::before {
            content: "";
            display: inline-block;
            height: 4px;
            width: 4px;
            border-radius: $border-radius-rounded;
            border: 1px solid $color-text-grey-medium;
            background-color: $color-text-grey-medium;
            margin-right: 4px;
          }
        }

        &.online {
          &::before {
            content: "";
            display: inline-block;
            height: 4px;
            width: 4px;
            border-radius: $border-radius-rounded;
            border: 1px solid $color-action;
            background-color: $color-action;
            margin-right: 4px;
          }
        }
      }
    }

    .options {
      display: flex;
      margin-left: auto;
      padding-left: 8px;

      .icon-vertical-dots {
        width: 10px;
        margin-left: 4px;
      }

      &.add-to-chat{
        i{
          width: 10px;
        }
      }

      span, a {
        i {
          fill: $color-primary;
          width: 14px;
        }

        color: $color-primary;
        cursor: pointer;
        margin: 0 4px;
      }
    }
  }
}