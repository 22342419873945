@import 'src/sass/_variables.scss';

.password-strength{
    display: inline-flex;
    flex-direction: column;
    .password-strength-bar{
        display: flex;
        .password-strength-bar-item{
            flex:1;
            background: $v3-color-bg-light-grey;
            border-radius: $v3-default-border-radius;
            height: 4px;
            margin-right: 8px;
            &.active{
                background: $color-green;
            }
            &:last-child{
                margin-right: 0;
            }
        }
    }
    .password-strength-detail{
        margin-top: 8px;
        font-size: .75em;
        .password-strength-detail-item{
            display: inline-flex;
            padding: 2px 4px;
            margin: 2px;
            border-radius: 2px;
            background: $v3-color-bg-light-grey;
            font-weight: 500;
            font-size: .9em;
        }
    }
}