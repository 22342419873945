@import '../../../sass/variables';

.age-confirmation {
    padding: 16px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    p{
        font-size: .95rem;
        color: #555;
        margin-bottom: 26px;
        line-height: 1.35em;
        padding: 0px 6px;
        b{
            font-weight: 600;
            color: #000;
        }
    }
}

.age-confirmation-loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 20px;
    
    .loading-icon {
        font-size: 2rem;
        color: #ff6600; // Couleur principale (ajuste selon ton thème)
        animation: spin 1s linear infinite;
        margin-bottom: 10px;
    }

    p {
        font-size: 1rem;
        color: #666;
    }
}

// Animation du spinner
@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}


.age-confirmation-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 2.2em;
    margin-bottom: 36px;
    margin-top: 20px;
    .svg-inline--fa{
        color: $v3-color-primary;
    }
    h2{
        margin-left: 16px;
        font-size: 1.35rem;
    }
}

.age-confirmation-icon {
    font-size: 30px;  /* Taille réduite */
    color: #ff6b6b;  /* Teinte plus foncée */
}

.age-confirmation-title {
    font-size: 22px;
    font-weight: bold;
    color: #333;
}

// .age-confirmation-text {
//     font-size: 16px;
//     line-height: 1.2;
//     color: #555;
//     margin-bottom: 12px;
//     padding: 0px 12px;
// }

.age-confirmation-buttons {
    width: 400px;
    margin-top: 12px;
    display: flex;
    gap: 12px;
}


@media screen and (max-width: $screen-size-small) {
    .age-confirmation-buttons {
        width: 100%;
        flex-wrap: wrap;
    }
}

//NEW DESIGN

.age-confirm-drawer{
    padding: 16px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &-header{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        font-size: 2.2em;
        margin-bottom: 36px;
        margin-top: 20px;
        .svg-inline--fa{
            color: $v3-color-primary;
        }
        h2{
            margin-left: 16px;
            font-size: 1.35rem;
        }
    }
    p{
        font-size: .95rem;
        color: #555;
        margin-bottom: 26px;
        line-height: 1.35em;
        padding: 0px 6px;
        small{
            font-size: .8rem;
            a{
                color: $v3-color-primary;
                text-decoration: none;
                &:hover{
                    text-decoration: underline;
                }
            }
        }
        b{
            font-weight: 600;
            color: #000;
        }
    }
    &-list{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    &-list-item{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        color: #000;
        font-weight: 600;
        margin-bottom: 26px;
        padding: 16px;
        cursor: pointer;
        border-radius: $v3-default-border-radius;
        border: 1px solid $v3-color-bg-light-grey-hover;
        box-shadow: $box-shadow;
        width: 100%;
        &:hover{
            background-color: $v3-color-bg-light-grey;
        }
    }
}