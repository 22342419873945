/*
.player-wrapper {
  //position: relative;
  //padding-top: 56.25%;
  max-height: 90%;
}*/

.react-player {
  /*
  position: absolute;
  top: 34px;
  left: 0;
  max-height: 90%;
  */

  &.player-blur{
    /*filter: blur(20px);
    background: #000;
    opacity: 0.6;*/
    video{
      filter: blur(8px);
      background: #000;
      opacity: 0.7;
    }
  }
  &.player-blur:-webkit-full-screen {
    filter: blur(8px);
    background: #000;
    opacity: 0.7;
  }

  .react-player__preview{
    background-size: contain !important;
    background-repeat: no-repeat;
  }
  
  .big-player{
    width: 50px;
    fill:#FFFFFF;
  }
}

.player-archive{
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;

  .player-archive-content{
    width: 80%;
    display: flex;
    justify-content: space-around;
    color: #FFFFFF;
    flex-direction: column;
    align-items: center;
    .player-icon{
      width: 150px;
      margin-bottom: 30px;
    }
    .player-text{
      font-weight: bold;
      letter-spacing: 0.7px;
      line-height : 20px;
      text-align: center;
      &.h1{
        font-size: 18px;
        margin-bottom: 16px;
      }
      &.h2{
        font-size: 22px;
      }
    }

    .player-footer {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 40px;

      .signup-cta {
        background-color: #2072ef;
        color: white;
        text-align: center;
        padding: 8px 64px;
        box-sizing: border-box;
        border-radius: 8px;
        border: none;
        outline: none;
        cursor: pointer;
        font-family: 'Poppins';

        .line1 {
          font-weight: 600;
          font-size: 20px;
          text-transform: uppercase;
        }
        .line2 {
          padding-top: 8px;
          font-size: 14px;
          font-weight: 200;
        }
      }

      .offer-cta {
        background-color: #2072ef;
        color: white;
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        padding: 16px 64px;
        box-sizing: border-box;
        border-radius: 8px;
        border: none;
        outline: none;
        cursor: pointer;
        font-family: 'Poppins';

      }

      .signin-cta {
        color: #FFFFFF;
        font-size: 16px;
        margin-top: 16px;
        //font-weight: 600;
        text-decoration: underline;
      }
    }

  }
}


.player-encourage-signup{
  position: absolute;
  width: 100%;
  //margin: 0 20%;
  font-family: "Poppins", sans-serif;
  line-height: 20px;
  animation: open-signup 0.7s;
  display: flex;
  justify-content: center;
  @keyframes open-signup {
    0%    { opacity: 0; }
    100%  { opacity: 1; }
  }
  @keyframes open-signup-window {
    0%    { transform: scale(0.7); }
    100%  { transform: scale(1); }
  }

  .window{
    animation: open-signup-window .5s ease-in;
  }

  .main-section{
    background: #FFFFFF;
    border-radius: 12px;
    padding: 16px;
    width: 33%;
    min-width: 500px;
    .form {
      h2 {
        text-align: center;
        font-weight: 600;
        font-size: 18px;
      }

      ul.arguments {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
          background-color: #f3f3f3;
          border-radius: 8px;
          padding: 12px 8px;
          box-sizing: border-box;
          font-weight: 500;
          font-size: 12px;
          display: flex;
          align-items: center;
          margin-bottom: 8px;

          .icon {
            /*width: 32px;
            text-align: center;
            margin-left: -6px;
            margin-right: 2px;*/
            padding: 0 8px;

            img {
              height: 18px;
            }
          }
          label {
            flex: 1;
          }
        }
      }

      .cta-list {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 32px;

        .signup-cta {
          background-color: #2072ef;
          color: white;
          text-align: center;
          padding: 8px 64px;
          box-sizing: border-box;
          border-radius: 8px;
          border: none;
          outline: none;
          cursor: pointer;
          font-family: 'Poppins';

          .line1 {
            font-weight: 600;
            font-size: 14px;
            text-transform: uppercase;
          }
          .line2 {
            font-size: 12px;
            font-weight: 200;
          }
        }

        .signin-cta {
          color: #4d4d4d;
          font-size: 12px;
          margin-top: 8px;
          font-weight: 600;
          text-decoration: underline;
        }
      }
    }

    .text {
      margin: 16px;
      font-size: 11px;
      color: black;
      line-height: 20px;
    }
  }

}

@media only screen and (max-width: $screen-size-medium) {
  .player-archive{
    .player-archive-content{
      .player-icon{
        width: 15%;
        min-width: 100px;
      }
    }
  }
}


@media only screen and (max-width: $screen-size-small) {
  .player-archive{
    .player-archive-content{
      .player-text{
        letter-spacing: 0.9px;
        &.h1{
          font-size: 12px;
        }
        &.h2{
          font-size: 14px;
        }

      }

      .player-footer{
        flex-direction: row;
        width: 100%;
        justify-content: space-around;
        align-items: baseline;
        flex-wrap: wrap;
        .signup-cta{
          padding: 8px 34px;
          .line1{
            font-size: 14px;
          }
          .line2{
            display: none;
          }
        }
      }
    }
  }
}


