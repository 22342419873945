.date-separator {
  display: block;
  text-transform: uppercase;
  color: lighten($color-text-medium-2, 20);
  font-size: 12px;
  margin-top: 32px;
  margin-bottom: 24px;
  text-align: center;
  width: 100%;
  user-select: none;
}
