.status-card {
  display: flex;
  background: #FFF;
  border-radius: $border-radius;
  padding: 20px;
  box-shadow: 0 2px 60px 0 rgba(0, 0, 0, 0.50);

  .topline {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    color: $color-primary;
    width: 20%;
    margin-right: 8px;

    .avatar {
      width: 50px;
      height: 50px;
    }

    .user-thumb {
      flex-shrink: 0;
      margin: 0;
    }
  }

  .wrapper {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    padding-left: 20px;
    justify-content: space-between;

    .title {
      width: 100%;
      font-size: 18px;
      font-weight: bold;
      line-height: 1.1em;
      color: $color-text-medium-2;
      word-break: break-word;
    }

    .content-status, .sharemedias-status {
      word-break: break-all;
    }

    .sharemedias-status {
      display: flex;
      flex-wrap: wrap;
      overflow: auto;

      .shared-media {
        background: #FFF;
        border: 0px solid transparent;
        margin: 2px;
        flex-grow: 1;
        max-width: calc(100% / 7 - 4px);
        justify-content: center;
        align-items: center;
        padding: 0px;

        .img-container {
          justify-content: center;
          align-items: center;
          display: flex;

          a {
            margin-top: 0 !important;

            img {
              max-width: 100%;
            }
          }
        }

        .details {
          display: none;
        }
      }
    }

    .infos {
      display: flex;
      align-items: flex-end;
      padding-top: 8px;
      flex: 1 1;

      .symbols {
        .gender {
          margin-left: 0;
          font-size: 0.8em;
        }
      }

      .username-time {
        font-size: 12px;
        color: $color-primary;
      }
    }

    .date {
      font-size: 12px;
      color: $color-text-grey-medium;
    }
  }
}

@media screen and (max-width: $screen-size-medium) {
  .status-card {
    .wrapper {
      .sharemedias-status {
        .shared-media {
          max-width: calc(100% / 5 - 8px);
        }
      }
    }
  }
}
