.wrapper-profil-informations {
  display: flex;
  flex: 1;

  .main-wrapper {
    padding-right: 0px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    .card {
      flex: 1;
      box-shadow: unset;
    }


    select {
      width: 100%;
      outline: none;
      position: relative;
    }

    input[type="date"], input[type="text"], input[type="password"] {
      width: 100%;
    }

    textarea {
      resize: vertical;
      color: $color-text-default;
    }
  }

  .description {
    padding: 8px;
    font-size: 16px;
    font-weight: 400;
    color: $color-text-medium-2;
    margin-bottom: 8px;
    font-family: $font-for-readability;
  }

  .no-content {
    margin-bottom: 8px;

    .validate-toggle-displayInMeet {
      position: relative;

      span {
        opacity: 0;
        visibility: hidden;
        position: absolute;
        transition: all .2s linear;
        transform: translateY(-20px);
        left: 0;
        width: 100%;
      }
    }

    .toggle {
      &.isToggled {
        background-color: darken($color-green, 15);
      }
    }

    &.active {
      color: #FFF;
      background-color: $color-green;
      border: 1px solid $color-green;

      .validate-toggle-displayInMeet {
        span {
          opacity: 1;
          visibility: visible;
          transform: translateY(0);
        }
      }
    }
  }

  .completed-informations {
    border-radius: $border-radius;
    background-color: $color-main;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08);

    .head-title {
      color: #FFF;
      font-size: 15px;

      .icon-info-circle {
        fill: #FFF;
        width: 16px;
      }
    }

    .dropdown {
      .dropdown-relatif {
        .dropdown-menu {
          .menu-item {
            color: $color-text-default;
            cursor: inherit;

            .validate-information, .missing-information {
              margin: 8px 4px;
              width: 100%;
              font-size: 12px;
              font-weight: bold;
              text-transform: none;

              i {
                margin-right: 0;
              }
            }

            .validate-information {
              i {
                fill: $color-green !important;
              }
            }

            &:hover {
              color: $color-text-default;

              i {
                fill: darken($color-grey-light, 20);
              }
            }
          }
        }
      }
    }

    .profileMeet-progress-bar-container {
      height: 10px;
      width: 100%;
      border-radius: 10px;
      background-color: darken($color-main, 15);
      display: flex;
      align-items: center;
      position: relative;

      .progress-bar {
        transition: all .1s linear;
        height: 100%;
        background-color: #FFF;
        border-radius: 10px;
        width: 0%;
      }
    }

    .no-content {
      margin-top: 16px;
      margin-bottom: 0;
    }
  }

  .modify-meet-informations {
    background-color: #FFF;
  }

  .informations-icon{
    margin-right: 8px;
    color: #000;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #f5f5f5;
    min-height: 32px;
    min-width: 32px;
    font-size: 1.1em;

    padding: 0 8px;
    border-radius: 16px;
    user-select: none;
    position: relative;
    text-align: left;
  }

  .informations-title{
    color: #000000;
    font-size: 14px;
    font-weight: 500;
    padding-bottom: 12px;
  }

  .informations-general{
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid $color-grey-light;
    width: 100%;
    margin-bottom: 16px;
    &-container{
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      margin-bottom: 16px;
      &-subcontainer{
        width: calc(100% / 4);
        display: flex;
        font-size: 0.75em;
        font-weight: 500;
        &-title {
          font-weight: 500;
          color: $color-text-grey-medium;
          margin-bottom: 6px;
        }
        &-content{
          li{
            width: 100%;
            padding: 2px 0px;
            color: #000;
          }
        }
      }
    }
  }

  .informations-tendencies{
    display: flex;
    border-bottom: 1px solid $color-grey-light;
    width: 100%;
    margin-bottom: 24px;
    &-container{
      display: flex;
      flex-direction: column;
      font-size: 0.75em;
      font-weight: 500;

      &-content{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin-bottom: 16px;
        li{
          padding: 8px;
          color: #000;
        }
      }
    }
  }

  .informations-personality{
    display: flex;
    width: 100%;
    margin-bottom: 24px;
    &-container{
      display: flex;
      flex-direction: column;
      font-size: 0.75em;
      font-weight: 500;
      &-content{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin-bottom: 16px;
        li{
          padding: 8px;
          color: #000;
        }
      }
    }
  }

  .informations-person{
    display: flex;
    flex-wrap: wrap;

    width: 100%;
    &-container {
      width: 100%;
      &-group {
        display: flex;
        margin-bottom: 16px;

        &-title {
          display: flex;
          align-items: center;
          padding-right: 12px;
          padding-bottom: 0;
        }
      }

      &-options{
        border: 1px solid $color-grey-light;
        border-radius: 16px;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 16px;
        padding: 16px 16px 0px 16px;
        font-weight: 500;
        font-size: 0.75em;
        &-subcontainer {
          width: calc(100% / 4);
          display: flex;
          margin-bottom: 16px;

          &-title {
            color: $color-text-grey-medium;
            margin-bottom: 6px;
            font-weight: 500;
          }

          &-content {
            li {
              width: 100%;
              padding: 2px 0px;
              color: #000;

            }
          }
        }
      }
    }
  }

  .informations-container {
    font-size: 13px;
    font-weight: 400;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    color: $color-text-default;
    background: #FFF;
    padding: 8px 0;
    margin: 8px 0;
    border-bottom: 1px solid $color-grey-light;

    h5 {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      font-weight: bold;
      color: $color-dark-light;
      font-size: 16px;
    }

    .content {
      width: 100%;
      padding: 0 0 8px 0;
      white-space: pre-line;
      flex-wrap: wrap;
      display: flex;
      line-height: 1.3em;
      h6 {
        color: $color-text-medium-2;
        margin-top: 16px;
        margin-bottom: 8px;

        &:first-child {
          margin-top: 0;
        }
      }

      .row {
        display: flex;
        align-items: center;
        margin: 4px 0;
        min-width: 150px;

        input[type="checkbox"], input[type="radio"] {
          width: auto;
          height: auto;
          margin: 8px 8px 8px 0;
        }
      }

      .look-for-display {
        flex-direction: column;
        align-items: unset;
        margin: 8px;
        flex-grow: 1;
        flex-shrink: 0;

        b {
          white-space: nowrap;
          margin-right: 4px;
          line-height: 18px;
          min-width: 70px;
          color: $color-text-medium-2;
        }

        div {
          display: flex;
          flex-wrap: wrap;
          line-height: 18px;

          span {
            padding: 4px;
            flex-grow: unset;

            &::after {
              content: "/";
              display: inline-block;
              margin-left: 8px;
            }

            &:last-child {
              &::after {
                content: "";
                display: inline-block;
                margin-left: 0px;
              }
            }
          }
        }
      }

      span {
        padding: 8px;
        //flex-grow: 1;
        &.noInformation {
          font-style: italic;
          padding: 0;
          width: 100%;
        }

        &.count {
          display: flex;
          justify-content: flex-end;
          padding: 4px;
        }
      }

      textarea {
        width: 100%;
        min-height: 65px;
      }

      &.medium {
        font-size: 14px;
        line-height: 18px;
        color: $color-text-default;
      }

      &.location {
        flex-direction: column;
      }
    }

    .last-forum-card {
      background: #FFF;
      border: 1px solid $color-grey-light;
      border-radius: $border-radius;
      padding: 20px;
      width: calc(100% / 3 - 16px);
      margin: 8px;

      .link {
        text-decoration: none;
        color: $color-primary;
      }

      span {
        white-space: nowrap;
      }

      .full {
        margin-top: auto;
      }

      .title {
        margin-bottom: 32px;
        @include overflow-ellipsis();

        a {
          color: $color-text-default;
          text-decoration: none;
          white-space: nowrap;
        }
      }
    }

    &.orientation {
      .content {
        display: flex;
        flex-direction: column;

        span {
          padding: 8px;
        }
      }
    }

    &.full {
      width: 100%;
    }

    &:first-child {
      border-top: 0px;
    }

    &:last-child {
      border-bottom: 0;
    }

    &.last-forum-post {
      border-bottom: 1px solid $color-grey-light;
      padding-bottom: 8px;
      //.btn{
      //  margin: 8px 0;
      //  margin-left: auto;
      //}
    }
  }

  .three-content-line {
    justify-content: space-between;
    margin-bottom: 8px;
  }

  .primary-reverse {
    margin: 8px auto;
    margin-top: 16px;
  }
}

@media screen and (max-width: $screen-size-medium) {
  .wrapper-profil-informations {
    max-width: 100%;
    width: 100%;

    .card {
      width: 100%;
    }

    .informations-general-container-subcontainer{
      width: 33%;
      margin-bottom: 16px;
    }
    .informations-person-container-options-subcontainer{
      width: 33%;
    }

    .lookingFor-content {
      &.last-forum-post {
        .last-forum-post-wrapper {
          flex-direction: column;

          .last-forum-card {
            flex-grow: 1;
            width: unset;
          }
        }
      }
    }

    .main-wrapper {
      padding-left: 0;
    }
  }
}

@media screen and (max-width: $screen-size-small) {
  .wrapper-profil-informations {

    .informations-general-container {
      display: flex;
      flex-wrap: wrap;
      gap: 16px; // Utilisez gap pour ajouter de l'espace entre les éléments

      .informations-general-container-subcontainer {
        flex: 1 1 calc(50% - 8px); // Ajustez la largeur pour inclure l'espace entre les éléments
        box-sizing: border-box;
        border: 1px solid #E9E9E9;
        border-radius: 16px;
        padding: 16px;
        margin-bottom: 0px;

        &:nth-child(1),
        &:nth-child(4) {
          flex: 1 1 100%;
        }

        &:nth-child(4) {
          order: 1; // Change l'ordre pour qu'il devienne le 2ème
        }

        &:nth-child(2) {
          order: 3; // Déplace le 3ème élément après le 4ème
        }

        &:nth-child(3) {
          order: 2; // Déplace le 2ème élément après le 2ème
        }

        &:nth-child(n+5) {
          order: 4; // Les éléments restants après le 4ème
        }


      }
    }
    .informations-person-container-options-subcontainer{
      width: 50%;
    }
    .main-wrapper {
      .flex-table {
        .flex-table-label {
          height: 80px;
        }

        .flex-table-content {
          height: 80px;
        }
      }

      .modify-meet-informations-container, .modify-meet-informations {
        width: 100%;

        .btn {
          flex-grow: 1;
        }
      }

      .topline {
        .lookingFor-content {
          width: 100%;
        }
      }

      .informations {
        margin: 0;
      }
    }
  }
}
