@import 'src/sass/_variables.scss';
.toggle {
  height: 22px;
  width: 42px;
  flex-shrink: 0;
  border-radius: 50px;
  background: #d6d6d6;
  user-select: none;
  cursor: pointer;
  // border: 1px solid #d6d6d6;
  // box-shadow : 0px 0px 0px 1px #d6d6d6;
  transition: all .26s ease;

  .toggle-point {
    height: 18px;
    width: 18px;
    box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.3);
    // border: 1px solid #d6d6d6;
    border-radius: 40px;
    margin-top: 2px;
    margin-left: 2px;
    background: #ffffff;
    transition: margin-left .26s ease;
  }

  &.isToggled {
    background: #000;

    .toggle-point {
      margin-left: 22px;
    }
  }
}
