// PADDING

.p-16 {
  padding: 16px;
}

.p-24 {
  padding: 24px;
}

.py-2 {
  padding-top: 2px;
  padding-bottom: 2px;
}

.py-4 {
  padding-top: 4px;
  padding-bottom: 4px;
}

.py-8 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.py-16 {
  padding-top: 16px;
  padding-bottom: 16px;
}

.py-24 {
  padding-top: 24px;
  padding-bottom: 24px;
}

.py-64 {
  padding-top: 64px !important;
  padding-bottom: 64px !important;
}

.px-4 {
  padding-left: 4px;
  padding-right: 4px;
}

.px-8 {
  padding-left: 8px;
  padding-right: 8px;
}

.px-16 {
  padding-left: 16px;
  padding-right: 16px;
}

.pb-4 {
  padding-bottom: 4px;
}

.pb-8 {
  padding-bottom: 8px;
}

.pb-16 {
  padding-bottom: 16px;
}

.pb-24 {
  padding-bottom: 24px;
}

.pt-8 {
  padding-top: 8px;
}

.pt-24 {
  padding-top: 24px;
}

.pr-8 {
  padding-right: 8px;
}

.pr-32 {
  padding-right: 32px;
}

.pl-0 {
  padding-left: 0 !important;
}

.pl-4 {
  padding-left: 4px;
}

.pl-8 {
  padding-left: 8px;
}

.pl-16 {
  padding-left: 16px;
}

.pl-24 {
  padding-left: 24px;
}

.pl-40 {
  padding-left: 40px;
}
