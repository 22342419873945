.card-forum-category {
  display: flex;
  flex-direction: column;
  padding: 0;
  box-shadow: $box-shadow;

  .category-date {
    padding: 15px;
    font-size: 12px;
    color: $color-text-grey-medium;
  }

  .link {
    flex: 1;

    .category-title {
      padding: 30px 15px;
      font-size: 32px;
      color: $color-secondary;
    }
  }

  .category-wrapper {
    margin-left: 5px;
    margin-bottom: 10px;
    padding: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .category-thumb-group {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 10px;

      .avatar {
        width: 32px;
        height: 32px;
        margin-left: -5px;

        &.more {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 10px;
          font-weight: 600;
          background-image: $gradient;
          color: #FFFFFF;
        }
      }
    }

    .category-infos {
      .last-post {
        font-size: 10px;
        color: $color-text-grey-medium;
      }

      .username {
        color: $color-primary;
        font-size: 14px;
      }
    }
  }

  .category-baseline {
    background: $color-secondary;
    color: #FFFFFF;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 15px 15px;
  }

  .link {
    text-decoration: none;
  }
}
