.avatar {
  border-radius: $border-radius-rounded;
  flex-shrink: 0;
  position: relative;
  .online{
    width: 32%;
    height: 32%;
    border-radius: 50%;
    background-color: #51C73B;
    z-index: 2;
    position: absolute;
    bottom: calc(14% - min(16%, 8px));
    right: calc(14% - min(16%, 8px));
    max-width: 16px;
    max-height: 16px;
    border: 2px solid white;
  }
  .avatar-container {
    border: 2px solid #FFF;
    border-radius: $border-radius-rounded;
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    img {
      margin: 0;
      padding: 0;
      width: auto;
      height: auto;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 0;
      object-fit: cover;
    }
    &.loading{
      background-color: $color-grey-light;
    }
  }

  &.gender-default, &.gender-man, &.gender-woman, &.gender-couple, &.gender-couple-lesbian, &.gender-couple-gay, &.gender-trav, &.guest {
    border: 2px solid transparent;
    background-color: #FFF;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 120% 120%;
    background-clip: border-box;
  }

  &.gender-default {
    border-color: $color-text-medium-2;
    .avatar-container{
      border-color: #FFF;
    }
  }

  &.gender-man {
    // border-color: $color-gender-man;
    background-image: linear-gradient(#2b8eff, #2b83ff);
    .avatar-container{
      border-color: #FFF;
    }
  }

  &.gender-woman {
    // border-color: $color-gender-woman;
    background-image: linear-gradient(#ff769d, #fe258a);
    .avatar-container{
      border-color: #FFF;
    }
  }

  &.gender-couple {
    // border-color: $color-gender-couple;
    background-image: linear-gradient(45deg, #0044ff, #fe258a);
    .avatar-container{
      border-color: #FFF;
    }
  }

  &.gender-couple-lesbian {
    border-color: $color-gender-couple-lesbian;
    .avatar-container{
      border-color: #FFF;
    }
  }

  &.gender-couple-gay {
    border-color: $color-gender-couple-gay;
    .avatar-container{
      border-color: #FFF;
    }
  }

  &.gender-trav {
    border-color: $color-gender-trav;
    .avatar-container{
      border-color: #FFF;
    }
  }

  &.guest {
    border-color: $color-text-default;
    .avatar-container{
      border-color: #FFF;
    }
  }

  &.group {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    overflow: hidden;
    border: 2px solid $color-text-default;

    .avatar-container {
      //all: unset;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      flex-wrap: wrap;
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      border-radius: $border-radius-rounded;
      overflow: hidden;
      border: 1px solid #FFF;
      flex-shrink: 0;

      .group-img {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%;
        height: 50%;
        overflow: hidden;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        img {
          border-radius: 0;
          border: 0;
        }

        &:first-child {
          height: 100%;
          width: calc(50% - 1px);
          overflow: hidden;
        }

        &:nth-child(2) {
          border-bottom: 1px solid #FFF;
        }
      }
    }
  }
}
