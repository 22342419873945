#main, main {
  flex: 1;
  display: flex;
  flex-direction: column;
}


.mail-to {
  margin: 10px !important;
  bottom: 0px;
  position: fixed;
  margin-bottom: 20px !important;
  left: 23%;
  display: block;
  color: white;
  background: linear-gradient(90deg,#0c67f1,#2276f4);
  padding: 10px;
  box-sizing: border-box;
  border-radius: 20px;
  font-size: 14px;
  text-decoration: none;
  text-align: center;
  width: 90px;
  transition: width 0.3s;
  white-space: nowrap;
  font-weight: 600;
}
.mail-to:hover {
  width: 270px;
}
.mail-to::before {
  content: "Avis V2";
}
.mail-to:hover::before {
  content: "Donner mon avis sur la V2";
}

.container {
  margin: auto;
  padding: 0 10px;
  width: 100%;
  max-width: $screen-size-desktop;
  &.small {
    max-width: $screen-size-small;
  }
}

.layout-sidebar {
  display: flex;
  flex-direction: row;
  max-width: $screen-size-desktop;

  section:first-child {
    margin-top: 0;
  }

  .container {
    width: 100%;
  }

  .sidebar {
    position: static;
    top: 118px;
    width: $size-sidebar;
    min-width: $size-sidebar;

    &:last-child {
      padding-right: 0px;
    }
  }

  .main-wrapper {
    flex: 1;
    max-width: $screen-size-desktop - $size-sidebar;
  }

  & > :last-child {
    padding-right: 0px;
  }

  & > :first-child {
    padding-left: 0px;

  }

  .main-wrapper {
    padding: 0 8px;

  }

  .sidebar {
    padding-right: 8px;
  }
}

.responsive {
  display: none;
}

.alert-bar {
  background: lighten($color-red, 30);
  border-bottom: 1px solid lighten($color-red, 10);
  color: darken($color-red, 3);
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;

  &.info-beta {
    background: #f0e8f3;
    border-bottom-color: #55558c;
    color: #55558c;
    text-align: center;
    line-height: 15px;
    font-size: 12px;    

    i {
      fill: #1e3962;
    }
  }
  .multiline {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .alert-bar-message {
    text-align: center;
    line-height: 1.25rem;
    padding: 0 16px;
    font-size: .95rem;
    font-weight: 600;

    a {
      color: darken($color-red, 3);
      text-decoration: underline;
    }
  }

  i {
    fill: $color-red;
  }
}

@media screen and (max-width: $screen-size-desktop) and (min-width: $screen-size-medium) {
  .container {
    width: 100%;
    max-width: $screen-size-desktop;
  }
}

@media screen and (max-width: $screen-size-medium) {
  main {
    min-height: 100vh;
  }
  .responsive-hidden {
    display: none !important;
  }
  .container {
    width: 100%;
    max-width: $screen-size-medium;

    .layout-sidebar {
      flex-direction: column;

      .sidebar {
        position: static;
        top: 118px;
        width: 100%;
        min-width: $size-sidebar;
        padding-right: 0;
        padding-left: 0;
      }

      .main-wrapper {
        max-width: $screen-size-desktop;
      }
    }
  }
}

@media screen and (max-width: $screen-size-small) {
  .alert-bar {
    flex-direction: column;

    .alert-bar-message {
      font-size: .8rem;
    }

    .btn {
      margin-top: 8px;
    }
  }
}

