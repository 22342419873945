.form-field {
  display: flex;
  flex-direction: column;

  input[type="text"], input[type="url"], input[type="password"], input[type="date"], input[type="email"], input[type="datetime-local"], input[type="number"], select {
    width: 100%;
    font-family: $font-for-readability;
  }

  input[type="text"][disabled],
  input[type="url"][disabled],
  input[type="password"][disabled],
  input[type="date"][disabled],
  input[type="email"][disabled],
  input[type="datetime-local"][disabled],
  input[type="number"][disabled],
  select[disabled] {
    background: #bbbbbb;
    cursor: not-allowed;
  }

  a {
    color: $color-primary !important;

  }

  .error-message {
    font-size: 12px;
    color: $color-red;
    padding: 8px 0;
  }

  textarea {
    height: 100%;
    flex-grow: 1;
    font-family: $font-for-readability;
  }
  &.input-birthday{
    flex-direction: row;
    .date-select{
      margin-right: 12px;
    }
    .date-select:last-child{
      margin: 0;
    }
  }
  &.custom-add-input{
    .error{
      i{
        background-color: lighten($color-red, 30);
        border: 1px solid $color-red;
        border-left: 0;
      }
    }
  }
  input[type="text"], input[type="url"], input[type="password"], input[type="date"], input[type="email"], input[type="datetime-local"], input[type="number"], select {
    background-color: #FFF;
    padding: 0 8px;
    height: 32px;
    border: 1px solid darken($color-grey-light, 10);
    border-radius: $border-radius;
    color: $color-text-medium-2;
    font-size: 15px;
    outline: none;
    @include placeholder {
      color: lighten($color-text-medium-2, 20);
    }
  
    &.bottom-bar-style {
      border: 0px solid transparent;
      border-bottom: 2px solid $color-main;
      padding: 4px 0;
      border-radius: 0;
    }
  
    &.uploads-album {
      border: 1px solid $color-grey-light;
      border-bottom: 1px dashed $color-grey-light;
      border-radius: 4px 4px 0 0;
      background-color: #FFF;
    }
  
    &.search-bar {
      border: 0px solid transparent;
      width: 380px;
      border-radius: 50px;
      padding: 0px 16px;
      padding-left: 46px;
      height: 40px;
      outline: 0;
      margin-right: 16px;
      font-size: 15px;
      //background: lighten($color-main, 9);
      color: #FFF;
      background: rgba(255, 255, 255, 0.35);
      @include placeholder {
        color: rgba(255, 255, 255, 0.6);
        font-weight: 400;
      }
  
      &:focus, &:active {
        background: #FFF;
        text-decoration: none;
        color: #000;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
        outline: none;
        transition: all 0.3s;
        @include placeholder {
          color: rgba(0, 0, 0, 0.5);
          font-weight: 400;
        }
      }
    }
  
    &.error {
      background-color: lighten($color-red, 30);
      color: $color-red;
      border: 1px solid $color-red;
    }
  }
  
  [type="radio"]{
    &:not(:checked), &:checked {
      position: absolute;
      left: -9999px;
    }
    &:not(:checked) + label, &:checked + label{
      position: relative;
      padding-left: 20px;
      cursor: pointer;
      line-height: 20px;
      display: inline-block;
    }
    &:checked + label:before, &:not(:checked) + label:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 12px;
      height: 12px;
      border: 1px solid darken($color-grey-light, 10);
      border-radius: 100%;
      background: #fff;
    }
    &:checked + label:after, &:not(:checked) + label:after {
      content: '';
      width: 8px;
      height: 8px;
      background: $color-primary;
      position: absolute;
      top: 3px;
      left: 3px;
      border-radius: $border-radius-rounded;
      //transition: all 0.2s ease;
    }
    &:not(:checked) + label:after {
      opacity: 0;
      transform: scale(0);
    }
    &:checked + label:after {
      opacity: 1;
      transform: scale(1);
    }
    &.error{
      &:checked + label:before, &:not(:checked) + label:before {
        border: 1px solid $color-red;
        background: lighten($color-red, 30);
      }
    }
  }
  
  input[type="checkbox"] {
    background-color: transparent;
    position: absolute;
    z-index: 2;
    opacity: 0;
    width: 14px;
    height: 14px;
    left: 0px;
    top: 0px;
    margin: 0;
    cursor: pointer;
  
    & + label:before {
      content: '';
      margin-right: 8px;
      display: inline-block;
      width: 12px;
      height: 12px;
      border: 1px solid darken($color-grey-light, 10);
      border-radius: 2px;
      cursor: pointer;
      flex-shrink: 0;
    }
  
    &:checked + label:before {
      background: #FFF;
    }
  
    &:checked + label:after {
      display: block;
      top: 0;
      left: 0;
      width: 14px;
      height: 15px;
      background-repeat: no-repeat;
      background-position: center;
      content: '';
      background-image: url('data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjI0cHgiIGhlaWdodD0iMjRweCIgdmlld0JveD0iMCAwIDQ0Mi41MzMgNDQyLjUzMyIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDQyLjUzMyA0NDIuNTMzOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnPgoJPHBhdGggZD0iTTQzNC41MzksOTguNDk5bC0zOC44MjgtMzguODI4Yy01LjMyNC01LjMyOC0xMS43OTktNy45OTMtMTkuNDEtNy45OTNjLTcuNjE4LDAtMTQuMDkzLDIuNjY1LTE5LjQxNyw3Ljk5M0wxNjkuNTksMjQ3LjI0OCAgIGwtODMuOTM5LTg0LjIyNWMtNS4zMy01LjMzLTExLjgwMS03Ljk5Mi0xOS40MTItNy45OTJjLTcuNjE2LDAtMTQuMDg3LDIuNjYyLTE5LjQxNyw3Ljk5Mkw3Ljk5NCwyMDEuODUyICAgQzIuNjY0LDIwNy4xODEsMCwyMTMuNjU0LDAsMjIxLjI2OWMwLDcuNjA5LDIuNjY0LDE0LjA4OCw3Ljk5NCwxOS40MTZsMTAzLjM1MSwxMDMuMzQ5bDM4LjgzMSwzOC44MjggICBjNS4zMjcsNS4zMzIsMTEuOCw3Ljk5NCwxOS40MTQsNy45OTRjNy42MTEsMCwxNC4wODQtMi42NjksMTkuNDE0LTcuOTk0bDM4LjgzLTM4LjgyOEw0MzQuNTM5LDEzNy4zMyAgIGM1LjMyNS01LjMzLDcuOTk0LTExLjgwMiw3Ljk5NC0xOS40MTdDNDQyLjUzNywxMTAuMzAyLDQzOS44NjQsMTAzLjgyOSw0MzQuNTM5LDk4LjQ5OXoiIGZpbGw9IiMwMDAwMDAiLz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K');
      background-size: 9px 9px;
      position: absolute;
      cursor: pointer;
    }
  
    &.classic {
      background-color: #FFF;
      position: static;
      z-index: 2;
      opacity: 1;
      width: 15px;
      height: 15px;
      margin: 0;
    }
    &.error{
      & + label:before {
        background-color: lighten($color-red, 30);
        border: 1px solid $color-red;
      }
    }
  }
  
  textarea {
    background-color: #FFF;
    padding: 8px;
    height: 50px;
    line-height: 18px;
    border: 1px solid darken($color-grey-light, 10);
    border-radius: $border-radius;
    color: $color-text-medium-2;
    font-size: 15px;
    outline: none;
    @include placeholder {
      color: lighten($color-text-medium-2, 5);
    }
  
    &.uploads-album {
      height: 100px;
      border: 1px solid $color-grey-light;
      background-color: #FFF;
      border-top: none;
      padding: 8px;
      outline: none;
      border-radius: 0 0 4px 4px;
      resize: none;
      overflow-y: auto;
    }
  
    &.uploads-complete {
      height: 80px;
    }
  
    &.error {
      background-color: lighten($color-red, 30);
      color: $color-red;
      border: 1px solid $color-red;
    }
  
    &.bottom-bar-style {
      border: 0px solid transparent;
      border-bottom: 2px solid $color-main;
      padding: 4px 0;
      border-radius: 0;
      height: 32px;
    }
  }
}

.date-popup{
  min-width: unset;
  .react-datepicker__portal{
    all: unset;
  }
}

.password-input-wrapper {
  position: relative;
  display: flex;
  align-items: center;

  input {
    padding-right: 40px;
  }

  .show-password {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    padding: 8px 16px;
    cursor: pointer;

    .icon-svg {
      height: 12px;
      width: 12px;
    }
  }
}



.select-wrapper {
  display: flex;
  align-items: center;
  position: relative;

  select {
    padding: 0;
    appearance: none;
    border: 0;
    border-radius: 0;
    border-bottom: 2px solid darken($color-grey-light, 10);
    width: 100%;
  }

  .icon-chevron-down {
    position: absolute;
    right: 0;
    width: 10px;
  }
}

.select {
  height: 32px;

  .select__control {
    min-height: 32px;
    height: 100%;

    .select__value-container {
      height: 100%;
    }

    .select__input {
      height: 100%;
    }

    .select__menu {
      z-index: 2;
    }
  }
}

.custom-add-input{
  input {
    width: 50%;
    height: 30px;
    border-right: 0px;
    border-radius: $border-radius 0 0 $border-radius;
    padding: 8px;
    font-size: 15px;
    outline: 0;
  }

  i {
    cursor: pointer;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid darken($color-grey-light, 10);
    padding: 8px 16px;
    border-radius: 0 4px 4px 0;
  }

  .icon-plus-circle {
    svg {
      width: 18px;
      min-width: 18px;
      fill: $color-primary;
    }
  }
}
@media screen and (max-width: $screen-size-small) {
  .date-popup{
    align-items: center;
  }
}
