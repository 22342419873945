
#ProfileSubNavigation {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 16px;
  & > * {
    margin: 0 8px;
  }
  .btn-profile-subnav {
    // margin-bottom: 8px;
    padding: 0 16px;
    border-radius: 50px;
    font-size: .8em;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 38px;
    letter-spacing: -0.02em;
    font-weight: 500;
    color: $color-text-default;
    user-select: none;
    cursor: pointer;
    background: rgba(241, 241, 241, 0.85);
    &.no-cursor{
      cursor: default;
    }
    svg {
      margin-right: 8px;
      font-size: 1.1em;
    }

    &.primary {
      background: $color-primary;
      color: #FFFFFF;
      font-size: .9em;
    }

    &.primary-reverse {
      background: lighten($color-primary, 41);
      border: 0;
      color: darken($color-primary, 3);
    }
    &.btn-profile-subnav-more-actions{
      height: 38px;
      width: 38px;
      padding: 0;
      svg {
        margin-right: 0px;
      }
    }

    &:last-child {
      margin-bottom: 0px;
    }
    & > * {
      display: flex;
    justify-content: center;
    align-items: center;
    }
  }

  @media screen and (max-width: $screen-size-medium) {
    margin-top: 12px;
    margin-bottom: 24px;
    & > * {
      margin: 0;
    }
    align-items: flex-start;
    flex: 1;
    width: 100%;
    display: flex;
    border-radius: 0px;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding-bottom: 0px;
    font-size: .9em;
    
    .btn-profile-subnav{
      flex: 1;
      margin: 0 2px !important;
      &:first-child{
        margin-left: 8px !important;
      }
      &:last-child{
        margin-right: 8px !important;
      }
      &.btn-profile-subnav-more-actions{
        margin-left: 2px !important;
      }
    }
  }
}