.in-progress {
  width: 100%;
  position: relative;
  margin: 8px 0;

  #progress-bar {
    position: relative;
    width: 100%;
    overflow: hidden;
    background-color: $color-grey-light;
    border: 1px solid #E2E1E2;
    border-radius: $border-radius;
    flex-direction: row;
    padding: 8px 0;

    .text {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      color: $color-text-default;
      font-weight: 700;
      font-size: 12px;
      text-align: center;
      z-index: 4;
      padding: 4px 16px;
    }

    .loading-bar {
      z-index: 1;
      position: absolute;
      left: 0;
      top: 0;
      width: 0%;
      height: 100%;
      background-color: lighten($color-grey-medium, 40%);
      text-align: center;
      color: white;
      transition: width .2s linear;
    }
  }
}
