.head-title {
  color: #000;
  font-weight: bold;
  line-height: 1.2;

  .title-link {
    color: #000;
  }
}

.display-1 {
  font-size: 40px;
}

.display-2 {
  font-size: 26px;
}

.display-3 {
  font-size: 20px;
}

.display-4 {
  font-size: 18px;
}

.display-5 {
  font-size: 16px;
}

.display-6 {
  font-size: 14px;
}

.title-s1 {
  font-size: 26px;
  font-weight: 700;
  color: $color-text-default;
}

.title-s2 {
  font-size: 22px;
  font-weight: 700;
  color: $color-text-default;
}

.title-s3 {
  font-size: 18px;
  font-weight: 700;
  color: $color-text-default;
}

.title-s4 {
  font-size: 15px;
  font-weight: 700;
  color: $color-text-default;
}

.title-s5 {
  font-size: 13px;
  font-weight: 700;
  color: $color-text-default;
}

.title-background-header {
  background-color: $color-main;
  color: #FFF;
  border-radius: $border-radius $border-radius 0 0;
  padding: 16px 8px;
}

@media screen and (max-width: $screen-size-small) {
  .display-1 {
    font-size: 30px;
  }
  .display-2 {
    font-size: 22px;
  }
  .display-3 {
    font-size: 18px;
  }
  .display-4 {
    font-size: 16px;
  }
  .display-5 {
    font-size: 14px;
  }
  .display-6 {
    font-size: 12px;
  }
}
