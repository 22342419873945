#reporting {
  .form-status{
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    justify-content: space-between;
    .status {
      display: flex;
      align-items: center;
    }
    label{
      padding-right: 8px;
    }
  }

  .reporting-comments{
    overflow: auto;
  }

  .add-comment textarea{
    color:#000000;
  }

  .avatar{
    padding: 0px 8px 8px 0px;
    min-width: 96px;
  }
  .subject {
    display: flex;
    width: 100%;
    margin-bottom: 16px;
  }

  .flex-jc-sb{
    display: flex;
    justify-content: space-between;
  }
  .content-end{
    float: right;
  }
  .w-80{
    width: 80%;
  }

  .btn-submit{
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
  }

  .content {
    position: relative;
    display: flex;
    flex: auto;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem;
  }

  .content-header {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0,0,0,.03);
    border-bottom: 1px solid rgba(0,0,0,.125);
  }

  .content-header:first-child {
    border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
  }

  .content-body {
    flex: 1 1 auto;
    padding: 1.25rem;

    .message-report{
      font-weight: bold;
      display: flex;
    }

    .message-subject{
      padding-top: 32px;
      font-weight: bold;
      display: flex;
      cursor: pointer;
    }
  }
}