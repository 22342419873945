.friends-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;

  .topline {
    position: sticky;
    top: 0;
    background-color: #FFF;
    z-index: 2;

    .head-title {
      text-decoration: none;
    }

    .searchbar {
      width: 100%;
      padding: 8px;

      input {
        width: 100%;
        background-color: #FFF;

        &:focus {
          outline: none;
        }
      }
    }
  }

  .list {
    padding: 0 8px 12px 8px;

    h4 {
      font-size: 13px;
      color: $color-text-medium-2;
      padding: 8px 0;
      user-select: none;
    }

    .favourite {
      margin: 0 0 8px 0;
      padding-bottom: 16px;
    }
  }
}

#friends-list, #recent-visitors {
  width: 100%;

  .searchbar {
    width: 100%;
    padding: 8px 0;

    input {
      width: 100%;
      background-color: #FFF;

      &:focus {
        outline: none;
      }
    }
  }
}

.sidebar-friends-newsfeed {
  position: sticky !important;
  margin-top: 16px;
  margin-bottom: 8px;
  height: 100%;
  box-shadow: $box-shadow;
  background-color: #FFF;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 136px);
  padding-right: 0 !important;

  .friends-wrapper {
    display: flex;
    flex-direction: column;
    overflow: auto;
    flex-grow: 1;

    .topline {
      padding: 8px;
    }
  }
}

@media screen and (max-width: $screen-size-medium) {
  .sidebar-friends-newsfeed {
    max-height: calc(100vh - 118px);
  }
}

.sidebar-friends {
  flex: 1;
  background-color: #FFF;

  .list {
    overflow: hidden;
    max-height: 254px;
    margin: 8px 0;
    padding: 0 8px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    height: auto;

    .friends {
      display: flex;
      border: 0;
      padding: 0;
      width: 80px;
      width: calc(100% / 3 - 4px);
      margin: 2px;
      height: 80px;

      .loading {
        display: none;
      }

      a {
        display: flex;
        height: 100%;
        width: 100%;

        .avatar {
          display: flex;
          height: 100%;
          width: 100%;
          border-radius: 0;
          border: 0;
          overflow: hidden;

          .avatar-container {
            border: 0px solid transparent;
            border-radius: 0;
            position: relative;
            width: 100%;
            height: 100%;
            overflow: visible;

            img {
              border: 0;
              border-radius: 0;
            }
          }
        }
      }

      .infos-user-wrapper {
        display: none;
      }

      .options {
        display: none;
      }
    }
  }

  .friends-thumbs {
    display: flex;

  }

  .display-5 {
    padding: 16px 8px 8px 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .see-more {
      display: none;
    }
  }

  a {
    text-decoration: none;
  }

  .primary-reverse {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    border-radius: 0px;

    &:hover {
      color: #FFF;
    }
  }
}


#recent-visitors {
  .title {
    padding: 0;
    box-shadow: none;
  }

  .options {
    display: none !important;
  }
}

#timeline-actu {
  margin-top: 16px;

  .card {
    &:first-child {
      margin-top: 0;
    }
  }

  .waiting {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 8px;
    margin: 16px 0;
    color: $color-text-grey-medium;
  }
}

@media screen and (max-width: $screen-size-medium) {
  .sidebar-friends {
    box-shadow: $box-shadow-1;
    padding: 8px;

    .title {
      padding: 0;
    }

    .list {
      display: none;
    }

    .primary-reverse {
      display: none;
    }
  }
}
