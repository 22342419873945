#articles {
  &:first-child {
    margin-bottom: 30px;
  }

  .card {
    border-radius: $border-radius;

    .carousel {
      margin-bottom: 8px;
    }

    .footer-articles{
      margin: 16px 0;
      text-align: justify;
      p {
        font-family: inherit;
        font-weight: normal;
        font-size: 0.9rem;
        line-height: 1.4;
        margin-bottom: .8333333333rem;
        text-rendering: optimizeLegibility;

        span{
          display: flex;
          justify-content: center;
          font-size: 0.9rem;
          background-color: rgba(51,51,51,0.1);
          padding: 0.25rem 0.5rem;
          border-radius: 3px;
        }
      }
      h2{
        font-family: inherit;
        font-weight: normal;
        font-size: 0.9rem;
        line-height: 1.4;
        margin-bottom: .8333333333rem;
        text-rendering: optimizeLegibility;
        display: flex;
        justify-content: center;
        font-size: 0.9rem;
        background-color: rgba(51,51,51,0.1);
        padding: 0.25rem 0.5rem;
        border-radius: 3px;
        font-weight: bold;
      }
    }

    .card-controls {
      background: rgba(255, 255, 255, 0.95);
      padding-bottom: 16px;
      padding-top: 0;
      flex: 1;

      .filters {
        display: flex;

        .dropdown {
          margin-right: 16px;

          .icon-sliders {
            margin-right: 4px;
          }

          &:last-child {
            margin-right: 0;
          }

          .dropdown-children {
            .ui-light {
              font-size: 14px !important;
              display: flex;

              &:hover {
                .icon-sliders {
                  fill: $color-primary;
                }
              }
            }
          }

          .dropdown-menu {
            width: 250px;

            .menu-item {
              justify-content: space-between;
              align-items: center;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: $screen-size-medium) {
  #articles {
    .container {
      padding: 0;

      .card {
        margin-top: 0;

        .card-controls {
          .filter-options {
            flex-direction: column;
          }

          .filters {
            .dropdown {

              .dropdown-children {
                width: 100%;

                .small {
                  width: 100%;
                  justify-content: center;
                }
              }

              .dropdown-content {
                height: unset;
                top: unset;
              }

              .dropdown-menu {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
