.dropdown {
  user-select: none;
  outline: none;
  max-width: 100%;

  .dropdown-children {
    cursor: pointer;

    &.active {
      .ui-light {
        color: $color-primary !important;

        .icon-sliders {
          fill: $color-primary;
        }
      }
    }
  }

  .dropdown-relatif {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    min-width: 100%;
    z-index: 10;
    animation: .25s popin;

    &.absolute{
      position: absolute;
    }

    &.right {
      //.icon-caret-up {
      //  top: -8px;
      //}

      .dropdown-content {
        top: 9px;
        right: -10px;
        left: auto;
      }
    }

    &.top {
      .icon-caret-up {
        bottom: 23px;
        top: unset;
        transform: rotate(180deg) scaleX(-1);
      }

      .dropdown-content {
        bottom: 29px;
        right: unset;
        left: -10px;
        top: unset;
      }
    }

    .icon-caret-up {
      position: absolute;
      top: 3px;
      right: 7px;
      fill: #FFF;
      z-index: 1;
    }

    .close-dropdown {
      display: none;
    }

    .dropdown-content {
      position: absolute;
      top: 9px;
      left: -10px;
      background: #FFF;
      border-radius: $border-radius;
      overflow: hidden;
      box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.4);
      display: block;
      overscroll-behavior: contain;

    }

    .dropdown-menu {
      width: 250px;
      display: block;

      .menu-item {
        display: flex;
        align-items: center;
        padding: 10px 16px;
        color: $color-text-default;
        text-decoration: none;
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
        cursor: pointer;
        transition: all .1s linear;

        i, .svg-inline--fa {
          width: 14px;
          margin-right: 16px;
          fill: darken($color-grey-light, 20);
          color: darken($color-grey-light, 20);
          transition: all .1s linear;

          &.icon-arrow-up {
            width: 12px;
            margin-right: 18px;
          }
        }

        a {
          text-decoration: none;
          color: $color-text-default;
          transition: all .1s linear;

          &:hover {
            color: $color-primary;
          }
        }

        &:last-child {
          border: none;
        }

        &:hover {
          color: $color-primary;

          a {
            color: $color-primary;
          }

          i {
            fill: $color-primary;
          }
        }

        &.menu-item-messenger {
          padding-top: 0;

          &:last-child {
            padding-bottom: 0;
          }
        }
      }

      .messenger-dropdown-style {
        padding: 8px;
        border-bottom: 1px solid $color-grey-light;

        &:last-child {
          border-bottom: 0px;
        }
      }

      &.hover-border-style {
        li.menu-item {
          opacity: .7;

          &:hover {
            color: $color-text-default;
            padding-left: 18px;
            opacity: 1;
            border-left: 3px solid $color-main;
          }
        }
      }
    }
  }

  &.tag-friend {
    .dropdown-relatif {
      .dropdown-content {
        max-height: 200px;
        overflow: auto;
        width: 260px;
        left: 0px;

        li {
          display: flex;
          list-style-type: none;
          align-items: center;
          padding: 8px;

          .avatar {
            width: 30px;
            height: 30px;
            margin-right: 8px;
          }
        }
      }
    }
  }

  &.tag-city {
    .dropdown-relatif {
      .dropdown-content {
        max-height: 200px;
        overflow: auto;
        width: 260px;
        left: 0px;

        li {
          display: flex;
          list-style-type: none;
          align-items: center;
          padding: 8px;

          .avatar {
            width: 30px;
            height: 30px;
            margin-right: 8px;
          }
        }
      }
    }
  }

  &.tag-box {
    position: fixed;
    z-index: 9999;
    .dropdown-relatif {
      .dropdown-content {
        max-height: 200px;
        overflow: auto;
        width: 260px;
        left: 0px;
        top: -20px;
        li {
          display: flex;
          list-style-type: none;
          align-items: center;
          padding: 8px;

          .avatar {
            width: 30px;
            height: 30px;
            margin-right: 8px;
          }
        }
      }
    }
  }


  &.chat {
    .dropdown-relatif {
      .dropdown-content {
        max-height: 200px;
        overflow: auto;
        width: 260px;
        left: auto;
        right: 0;
      }
    }
  }
}


@keyframes popin {
  from {
    transform: scale(.7);
    opacity: 0;
  }
  30% {
    transform: scale(1.1);
  }
  70% {
    transform: scale(.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes appear {
  from {
    bottom: -100vh;
    transition: all linear 0.2s;
  }
  to {
    bottom: 0;
  }
}

@keyframes opacityAppear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media screen and (max-width: $screen-size-medium) {
  .dropdown {
    user-select: none;
    outline: none;

    .icon-caret-up {
      display: none !important;
    }

    .dropdown-relatif {
      position: relative;
      min-width: 100%;
      z-index: 10;
      animation: .2s opacityAppear;

      .layer {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
        background-color: rgba(0, 0, 0, .35)
      }

      .dropdown-content {
        z-index: 2;
        animation: .2s appear;
      }

      &.right {
        .icon-caret-up {
          display: none;
        }

        .dropdown-content {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          bottom: 0;
          left: 0;
          right: 0;
          top: auto;
          max-height: 100%;
          overflow: auto;
          border-radius: 0;
          z-index: 101;
        }
      }

      &.top {
        .icon-caret-up {
          display: none;
        }

        .dropdown-content {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          bottom: 0;
          left: 0;
          right: 0;
          top: auto;
          max-height: 100%;
          overflow: auto;
          border-radius: 0;
          z-index: 101;
        }
      }

      .icon-caret-up {
        display: none;
      }

      .close-dropdown {
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 10;
        cursor: pointer;
        background-color: $color-red !important;
        color: #FFF !important;
        width: 100%;
        padding: 16px 8px;
        box-shadow: $box-shadow-light;
        font-size: 11px !important;
        font-weight: bold !important;
        text-transform: uppercase;
        flex-shrink: 0;
        height: 40px;
        margin: 0 !important;

        .icon-close {
          width: 10px;
          fill: #FFF;
        }
      }

      .dropdown-content {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        top: auto;
        max-height: 100%;
        border-radius: 0;
        z-index: 101;
        display: flex;
        flex-direction: column;
      }

      .dropdown-menu {
        width: 100%;
        display: block;
        background-color: #FFF;
        height: 100%;
        overflow: auto;

        .menu-item {
          display: flex;
          padding: 12px 16px;
          color: $color-text-default;
          text-decoration: none;
          font-size: 16px;
          cursor: pointer;

          a {
            text-decoration: none;
            color: $color-text-default;

            &:hover {
              color: $color-primary;
            }
          }

          &:last-child {
            border: none;
          }

          &:hover {
            color: $color-primary;
          }
        }
      }

      &.emoji-dropdown {
        .dropdown-content {
          height: auto;

          .emoji-mart {
            width: 100% !important;
            background-color: #FFF;
          }
        }
      }
    }
  }
}
