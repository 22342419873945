#memberManagement {
  .block {
    border-bottom: 1px solid $color-grey-light;

    &:last-child {
      border-bottom: 0px solid transparent;
    }
  }

  .avatar {
    width: 32px;
    height: 32px;
  }
}
