.filter {
  display: flex;
  justify-content: center;
  border: 1px solid #e2e2e2 !important;
  border-radius: 20px;
  overflow: hidden;
  max-width: 100%;

  li {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-grow: 1;
    color: $color-text-default;
    font-size: 13px;
    padding: 8px 16px;
    font-weight: 600;
    cursor: pointer;
    user-select: none;
    transition: all ease-in-out .1s;
    border-radius: 20px;
    
    &:hover {
      background-color: $color-grey-light;
    }

    &.active {
      background-color: #000 !important;
      color: #FFF;
     
    }
  }
}

.filter-gender {
  display: flex;
  align-items: center;
  padding: 16px;

  li {
    font-size: 14px;
    cursor: pointer;
    padding: 8px 16px;
    border-radius: $border-radius;
    margin-right: 8px;
    user-select: none;
    text-align: center;
  }

  &.forum-style {
    background-color: rgba(179, 66, 176, .45);
    border-bottom: 1px solid $color-grey-light;
    overflow: auto;

    span {
      margin-right: 8px;
      font-size: 15px;
      color: $color-dark-red;
    }

    li {
      color: #FFF;
      background-color: rgba(179, 66, 176, .60);
      opacity: .5;

      &.active {
        opacity: 1;
      }
    }
  }

  &.light {
    background-color: #FFF;
    border-radius: $border-radius;
    border: 1px solid $color-grey-light;

    li {
      background-color: $color-grey-light;
      color: $color-text-default;

      &.active {
        background-color: $color-primary;
        color: #FFF;
      }
    }
  }
}

