.warning-editor{
    .editor-container{
        //margin-top: 24px;
        flex: 1;
        position: relative;
        border-radius: 10px;
        background: #f3f3f3;
        padding-right: 30px;

    }

    .editor-wrapper{
        display: flex;
        align-items: center;
        width: 100%;
        max-width: 100%;
        //overflow: hidden;
        background-color: #FFF;
    }

    .editor-input{
        padding: 10px 14px;
        outline: 0;
        font-size: 14px;
        line-height: 1.15em;
        min-height: 200px;
        max-height: 200px;
        overflow-y: auto;
    }

    .editor-placeholder {
        color: #999;
        position: absolute;
        align-items: center;
        overflow: visible;
        top: 11px;
        left: 15px;
        user-select: none;
        pointer-events: none;
        font-size: 14px;
    }
}