.readonly-editor{
  img, video{
    max-width: 100%;
    max-height: 1000px;
  }
  .draft-uploaded-media{
    position: relative; /* Conteneur parent pour positionner l'overlay */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 12px 0;
    img, video{
      max-width: 100%;
      width: auto;
      border-radius: 16px;
      border: 1px solid darken($v3-color-bg-light-grey, 3);
      overflow: hidden;
      max-height: 1000px;
    }
    
  }

 
  

  .quoting-user{
    display: flex;
    flex-direction: column;
    border: 1px solid darken($v3-color-bg-light-grey, 3);
    width: 100%;
    padding: 12px;
    margin-bottom: 8px;
    border-radius: $v3-default-border-radius * 2;
    .blockquote--classic{
      all: unset;
      border-left: 0;
      padding-left: 0;
      font-style: italic;
    }
    .quoting-user-username{
      margin-left: auto;
      font-size: 14px;
    }
  }

  .draft-shared-media{
    display: flex;
    flex-direction: column;
    max-width: 350px;
    padding: 16px;
    text-decoration: none;
    border: 1px solid $color-grey-light;
    border-radius: $border-radius;
    .content{
      overflow: hidden;
      max-width: 100%;
      max-height: 200px;
      min-height: 200px;
      margin-bottom: 8px;
      overflow: hidden;
      border-radius: $border-radius;
      background-color: $color-grey-light;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      img{
        max-width: 100%;
        max-height: 100%;
        margin: 0 auto;
      }
    }
    .video{
      display: flex;
      justify-content: center;
      align-items: center;
      .btn-play{
        position: absolute;
        height: 48px;
        width: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50px;
        color: #e3e3e3;
        background: rgba(0, 0, 0, 0.85);
      }
    }
    .caption{
      border-top: 1px solid $color-grey-light;
      padding-top: 8px;
      font-weight: 600;
      @extend .break-long-text;
      color: $color-text-default;
    }
    &.article, &.album{
      .content{
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        flex-grow: 1;
      }

    }
    &.error{
      font-size: 14px;
      color: $color-text-grey-medium;
      text-align: center;
    }
  }

  .link{
    color: $color-primary;
  }

  .mentionned-name{
    color: inherit;
  }

  span[data-text]{
    @extend .break-long-text;
    font-family: $font-for-readability;
  }

  .emojiSelectPopoverGroups{
    width: unset;
  }
  .emojiSelectPopoverGroupItem{
    flex: 0 0 calc(100% / 7);
  }
  .DraftEditor-root{
    .public-DraftEditor-content{
      max-height: unset;
      min-height: unset;
      h1{
        font-size: 2em;
      }
      h2{
        font-size: 1.5em;
      }
      h3{
        font-size: 1.17em;
      }
      h4{
        font-size: 1em;
      }
      h5{
        font-size: 0.83em;
      }
      h6{
        font-size: 0.67em;
      }
    }
  }
}