@import 'src/sass/_variables.scss';
.text-error {
  color: $color-red;
  font-size: .8em;
  padding: 4px;
  font-weight: 500;
  line-height: 1.2em;
  a{
    color: $color-red;
    font-weight: 600;
    text-decoration: underline;
  }
  &.center {
    text-align: center;
  }
}