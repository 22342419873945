@import 'src/sass/_variables.scss';

#signin{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 32px 10px;
  // min-height: 300px;
  .signin-title{
    font-size: 1.5em;
    font-weight: 500;
    margin-bottom: 16px;
    color: #000;
    text-align: center;
  }
  .signin-subtitle{
    font-size: .9em;
    font-weight: 500;
    color: #444444;
    text-align: center;
    margin-bottom: 32px;
    line-height: 1.3em;
    letter-spacing: -0.01em;
    max-width: 300px;
  }
  form{
    max-width: 320px;
    width: 100%;
    .signin-forget-pass{
      font-size: .8em;
      font-weight: 500;
      color: #444444;
      text-align: center;
      margin-bottom: 16px;
      display: inline-block;
      width: 100%;
      cursor: pointer;
      &:hover{
        text-decoration: underline;
      }
    }
  }
}