.card-forums-small {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  padding: 0px !important;
  margin: 4px;
  width: 100%;
  max-height: 300px;

  .topline {
    display: flex;
    flex-direction: row;
    padding: 16px;
    margin-bottom: 32px;
    height: 36px;

    .avatar {
      height: 37px;
      width: 37px;
    }

    .infos {
      display: flex;
      flex-direction: column;
      margin-left: 16px;
      justify-content: center;

      .username-time {
        display: flex;
        flex-direction: column;
        font-size: 13px;
        color: $color-text-grey-medium;

        a {
          padding-bottom: 4px;
          text-decoration: none;
          color: $color-primary;
        }
      }

      .user-forums {
        font-size: 13px;
        color: $color-text-grey-medium;
        margin-top: 2px;
        text-decoration: none;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 16px;
    flex: 1;

    a, a:focus, a:active {
      color: $color-text-grey-medium;
      text-decoration: none;
      font-weight: 700;
    }

    .title-forum {
      font-size: 0.8em;
      font-weight: 400;
    }


    .wrapper-content {
      display: flex;
      flex-direction: column;
      padding-top: 32px;

      .title {
        font-size: 18px;
        line-height: 22px;
        font-weight: bold;
        margin-bottom: 16px;

        a, a:focus, a:active {
          color: $color-main;
        }

      }

      .text {
        font-size: 15px;
        line-height: 18px;
        margin-bottom: 16px;
        color: $color-text-grey-medium;
      }
    }
  }

  .baseline {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 24px;
    padding: 16px;
    background: $color-main;
    color: #FFF;
    border-radius: 0 0 4px 4px;
    font-size: 13px;
  }
}
