#contentModeration {
  margin-bottom: 16px;

  .head-title {
    margin: 8px 8px 16px 8px;
    font-size: 22px;
  }

  .filter {
    margin: 8px 0 16px 0;
    align-self: flex-start;
  }

  .moderation-topline {
    background-color: #FFF;
    width: 100vw;
    padding: 8px;

    h2 {
      margin: 32px 0;
      text-align: center;

      a {
        text-decoration: none;
        color: $color-text-default;
      }
    }

    .sidebar-menu {
      padding: 8px;
    }
  }
}

@media screen and (max-width: $screen-size-tablette) {
  #contentModeration {
    margin-bottom: 0;

    .moderation-topline {
      max-width: 100%;
    }

    .container {
      padding: 0;

      .card {
        box-shadow: none;
        margin-top: 8px;
      }
    }
  }
}
