@import "src/sass/variables";

.alert{
  padding: $v3-padding-drawer;
  width: 100%;
  .alert-footer{
    margin-top: 12px;
    display: flex;
    justify-content: flex-end;
  }
}