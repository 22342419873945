.answers-count {
  position: relative;
  top: unset;
  right: unset;
  color: #000;
  display: flex;
  align-items: center;
  font-size: 12px;
  background-color: transparent;
  font-weight: 400;

  span {
    margin-right: 4px;
  }
}