#subscribe {
  .container {
    max-width: 600px;
    //max-height: 400px;
    width: 100vw;
    display: flex;
    flex-direction: column;
    overflow: auto;
    background-color: #FFF;

    h3 {
      @extend .title-s1;
      padding: 8px;
      text-align: center;
      line-height: 1.3;

      span {
        color: $color-main;
      }
    }

    .advantage-container {
      margin: 16px 0;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      //height: 100%;
      //overflow: auto;

      .advantage-block {
        display: flex;
        align-items: center;
        flex: 1 1 50%;
        min-width: 200px;
        padding: 16px;

        .icon-container {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 16px;
          width: 40px;
          height: 40px;
          border-radius: $border-radius-rounded;
          background-color: $color-main;
          flex-shrink: 0;
          user-select: none;
          box-shadow: $box-shadow-light;

          .icon-svg {
            width: 20px;
            fill:#FFFFFF;
          }
        }
      }
    }

    .btn {
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }
}

@media screen and (max-width: $screen-size-small) {
  #subscribe {
    .container {
      margin-top: 0;
      max-height: calc(100vh - 38px);
    }
  }
}
