@import 'src/sass/_variables.scss';

button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    margin: 0;
    font: inherit;
    cursor: pointer;
  }
  
  button:focus {
    outline: none;
  }

.button-v3{
    color: inherit;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 18px;
    background: $v3-color-bg-light-grey;
    min-height: 36px;
    min-width: 36px;
    font-size: .75em;
    font-weight: 500;
    padding: 0 12px;
    user-select: none;
    position: relative;
    text-align: left;
    cursor: pointer;
    .svg-inline--fa{
        margin-right: 6px;
        font-size: 1.2em;
    }
    &:hover{
        background: $v3-color-bg-light-grey-hover;
    }
    &.active{
        background: #000;
        color: #FFF;
    }
    &.onlyIcon{
        .svg-inline--fa{
            margin-right: 0px;
        }
    }
    &.full{
        width: 100%;
        
    }
    &.disabled{
        background: $v3-color-bg-light-grey !important;
        color: #CCC  !important;
        cursor: not-allowed  !important;
        &:hover{
            background: $v3-color-bg-light-grey  !important;
        }
    }
    &.big{
        min-height: 44px;
        min-width: 44px;
        padding: 0 16px;
        border-radius: 22px;
        font-size: .95em;
        .svg-inline--fa{
            margin-right: 8px;
            font-size: 1.4em;
        }
        .button-loading{
            border-radius: 22px;
        }
    }
    &.small{
        min-height: 24px;
        min-width: 24px;
        padding: 0 8px;
        .svg-inline--fa{
            margin-right: 4px;
            font-size: 1em;
        }
        &.onlyIcon{
            padding: 0 4px;
            .svg-inline--fa{
                margin-right: 0px;
            }
        }
    }
    .button-loading{
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background: transparent;
        // opacity: .95;
        border-radius: 18px;
        color: #FFF;
        background-size: 400% 200%;
        background-image: linear-gradient(to right, $v3-color-primary, $v3-color-secondary, transparentize($v3-color-secondary, .2), $v3-color-secondary, transparentize($v3-color-primary, .1));
        animation: moveGradient 2s infinite;
        .button-loading-icon{
            opacity: .8;
        }
    }
    .button-badge{
        background: $v3-color-gradient;
        height: 16px;
        min-width: 16px;
        padding: 0 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100px;
        margin-left: 6px;
        font-size: .7em;
        font-weight: 600;
        color: #FFF;
        line-height: 0;
    }
    &.button-darken{
        background: darken($v3-color-bg-light-grey, 5%);
        color: #000;
        &:hover{
            background: darken($v3-color-bg-light-grey, 20%);
        }
    }
    &.button-reverse{
        background: #000;
        color: #FFF;
        &:hover{
            background: darken($v3-color-bg-light-grey-hover, 60%);
        }
    }
    &.button-cta{
        background: #000;
        color: #FFF;
        font-size: .8em;
        &:hover{
            background: darken($v3-color-bg-light-grey-hover, 60%);
        }
    }
    &.button-cta-light{
        background: #FFF;
        color: #000;
        font-size: .8em;
        &:hover{
            background: darken($v3-color-bg-light-grey-hover, 5%);
        }
    }
    &.button-clear{
        background: transparent;
        color: #000;
        &:hover{
            background: $v3-color-bg-light-grey;
        }
    }
    &.button-outline{
        background: transparent;
        border: 1px solid darken($v3-color-bg-light-grey, 3%);
        color: #000;
        &:hover{
            background: $v3-color-bg-light-grey;
        }
    }
    &.button-pink-gradient{
        background: linear-gradient(to right, $v3-color-primary, $v3-color-secondary);
        color: white;
        font-weight: 600;
        font-size: 0.87rem;
        min-height: 40px;
        border-radius: 20px;
        &:hover{
            background: linear-gradient(to right, darken($v3-color-primary, 10%), darken($v3-color-secondary , 10%));
        }
    }
    @keyframes moveGradient {
        0% {
          background-position: 0% 50%;
        }
        50% {
          background-position: 100% 50%;
        }
        100% {
          background-position: 0% 50%;
        }
      }
}