#spa-update{
    display: none;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 9999;
    width: 100%;
    height: 100%;
    background-color: rgba(125, 125, 125, 0.7);
    #spa-update-container{
        padding: 24px;
        border-radius: 12px;
        background-color: #FFF;
        max-width: 560px;
        display: flex;
        flex-direction: column;
        #spa-upadate-title{
            font-size: 1.3em;
            line-height: 1.2em;
            font-weight: 600;
        }
        #spa-upadate-content{
            margin-top: 18px;
            line-height: 1.2em;
            font-size: 1.1em;
        }
        #spa-update-refresh{
            margin-top: 12px;
            align-self:center;
            background-color: #2276f4;
            color: #FFF;
            padding: 12px 16px;
            border-radius: 12px;
            user-select: none;
            cursor: pointer;
        }
    }
    &.active{
        display: flex !important;
    }
}