@media screen and (max-width: $screen-size-small) {
  .alert-modal {
    height: 100%;

    .alert-text {
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }
}
