@import '../../sass/variables';
#ProfileExpendableDescription {
  margin-top: 0px;
  padding: 0 8px;
  //z-index: 1;
  .profile-link-external{
    color: $v3-color-link;
    cursor: pointer;
    display: flex;
    align-items: center;
    &:hover{
      text-decoration: underline;
    }
    .svg-inline--fa {
      color: $color-text-grey-medium;
      margin-right: 6px;
    }
  }
  .profile-group{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0px;
    font-size: 0.85em;
    color: #343434;
    font-weight: 500;
    flex: 1;
    .profile-description-entry{
      border-bottom:none;
      padding: 0;
      &:first-child{
        padding-top: 0px;
      }
    }
  }
  .profile-description-entry {
    margin-bottom: 8px;
    // border-bottom: 1px solid #eaeaea;
    //font-size: .85em;
    color: #343434;
    //font-weight: 500;
    line-height: 1.2;
    letter-spacing: 0.3px;
    font-size: 12px;
    font-weight: 500;
    white-space: pre-wrap;
    word-break: break-word;
    .more-description{
      display: flex;
      justify-content: center;
      cursor: pointer;
      font-weight: bold;
    }
    .profile-description-short{
      max-height: 200px;
      overflow: hidden;
    }

    .profile-description-entry-icon {
      width: 24px;
      display: inline-block;
      margin-bottom: 8px;
      svg {
        height: 13px;
      }
    }

    .profile-description-entry-title {
      text-transform: uppercase;
      font-size: .8em;
      margin-bottom: 4px;
      color: #515151;
      margin-top: 8px;
    }

    &.network{
      display: flex;
      cursor: pointer;
      .icon-network{
        fill: #fb4d4d;
      }
    }

    &.link, a {
      cursor: pointer;
      user-select: none;
      color: $color-primary;

      &:hover {
        text-decoration: underline;
        color: $color-primary;
      }

      &:visited {
        color: $color-primary;
      }
    }

    &.no-border {
      border-bottom: none;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  .profile-description-see-more {
    text-align: right;
    display: inline-flex;
    font-weight: 500;
    color: $color-text-default;
    user-select: none;
    justify-content: space-between;
    cursor: pointer;
    font-size: .8em;
    margin-top: 8px;
    svg {
      margin-right: 4px;
      color: $color-text-default;
    }
  }

  @media screen and (max-width: $screen-size-medium) {
    border-radius: 0px;
    padding: 0 16px;
    .profile-group{
      justify-content: space-evenly;
    }
    .btn-profile-subnav-wrapper {
      display: flex;
      border-radius: 0px;
      flex-wrap: wrap;
      padding-bottom: 0px;
      text-align: center;
    }

    .profile-description-entry {
      // width: calc(100% / 3);
      border-bottom: none;
      padding: 0px;

      &.md-100 {
        padding: 0;
        width: 100%;
        text-align: left;

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }

  // @media screen and (max-width: $screen-size-min) {
  //   .profile-group{
  //     justify-content: left;
  //     .profile-description-entry{
  //       width: 50%;
  //       padding-bottom: 16px;
  //     }
  //   }
  // }
}