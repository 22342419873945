#warningDetail {
  //max-width: 700px;
  width: 100vw;
  .link-group{
    display: flex;
    flex-wrap: wrap;
    flex:1;
    margin-right: 16px;
    a{
      height: 25px;
    }
  }
  .btn-close{
    margin: 4px;
  }
  .btn-accepted, .btn-deleted, .btn-status{
    height: 25px;
    min-width: initial;
    width: 140px;
  }
  .btn-status{
    border-color: coral;
    color: coral;
    &:hover{
      background-color: coral;
      color: #FFFFFF;
    }
  }
  .warning-detail-topline {
    display: flex;
    justify-content: center;
    padding: 16px;
    border: 1px solid lighten($color-red, 20);
    background-color: lighten($color-red, 35);
    border-radius: $border-radius;

    span {
      display: block;
      line-height: 1.5;
    }
  }

  .text {
    line-height: 1.3;
    @extend .break-long-text;
    font-family: $font-for-readability;
  }
}

@media screen and (max-width: $screen-size-medium) {
  #warningDetail {
    max-width: 100%;
    .justify-end {
      justify-content: center;
    }
    .modal-baseline{
      flex-direction: column;
      .btn-close{
        width: 100%;
        max-width: 560px;

        margin: 0px;

      }
      .link-group{
        margin-right: 0px;
        margin-bottom: 8px;
        a{
          margin: 6px;

        }
      }
    }

  }
}
