.MobileLiveTool {
  position: fixed;
  bottom: 8px;
  display: flex;
  //right: 8px;
  right: 16px;
  left: 16px;
  z-index: 9;
  align-items: center;
  background: rgba(255, 255, 255, 0.93);
  //border: 1px solid #d2d2d2;
  max-width: calc(100vh - 16px);
  border-radius: 150px;
  overflow: hidden;
  padding: 4px;
  padding-right: 12px;
  box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.5);
  animation: pop 0.2s linear 1;
  font-weight: 500;
  &.hidden {
    display: none;
  }

  .frame-wrapper {
    flex-shrink: 0;
    position: relative;
    height: 70px;
    width: 70px;
    background: #d2d2d2;
    border-radius: 70px;
    overflow: hidden;

    iframe {
      position: absolute;
      left: -35px;
      top: -12px;
      overflow: hidden;
      height: 160px;
      width: 160px;
      pointer-events: none;
    }
  }

  .live-infos-wrapper {
    padding: 0 16px;

    .live-name {
      margin-bottom: 4px;
      color: #000000 !important;
    }

    .live-cta {
      color: $color-primary;
    }
  }

  @keyframes pop {
    0% {
      transform: scale(0.95);
      opacity: 0
    }
    70% {
      transform: scale(1.05);
      opacity: 1
    }
    100% {
      transform: scale(1);
    }
  }
}