#webcamAds {
  display: flex;
  align-items: center;
  margin: 16px 0;
  width: 100%;

  .webcam {
    display: flex;
    align-items: center;
    width: calc((100% / 2) - 8px);
    margin-right: 8px;
    border-radius: $border-radius;
    overflow: hidden;
    height: 50px;

    .text {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 80%;
      padding: 16px;
      background-color: #F7C72F;
      color: $color-dark-red;
      font-weight: 700;

      .timer {
        padding: 8px 16px;
        background-color: darken(#F7C72F, 10%);
        margin-left: 16px;
        color: $color-dark-red;
        font-weight: 700;
      }
    }

    .btn-webcam-inscription {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 700;
      cursor: pointer;
      width: 20%;
      height: 100%;
      background-color: $color-redpink;
      color: #FFF;
      text-transform: uppercase;
      padding: 16px;
    }
  }

  .klingon {
    background: #cbcbcb;
    height: 90px;
    margin-left: 8px;
    width: calc((100% / 2) - 8px);
    display: flex;
    align-items: center;
    justify-content: center;
    background: url('../../images/images-test/175x175/10.jpeg');
    background-size: cover;
    color: #FFF;
    font-weight: bold;
  }
}

#webcamHeader {
  display: none;
  background-color: darken($color-main, 10);
  transition: all .1s linear;
  height: 100px;
  overflow: hidden;

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
    padding: 24px 16px;

    .timer-big {
      display: flex;
      align-items: center;
      font-size: 20px;
      font-weight: 700;

      .number {
        font-size: 35px;
        margin: 0 4px;
      }
    }

    .message-container {
      margin-left: 32px;

      .main-message {
        font-weight: 700;
        font-size: 20px;
      }

      .sub-message {
        font-size: 16px;
      }
    }

    .btn {
      background-color: $color-main;
      margin-left: 100px;
      text-transform: uppercase;
    }

    .close-ad {
      position: absolute;
      top: 8px;
      right: 8px;
      fill: #FFF;
      cursor: pointer;
      background-color: darken($color-main, 20);
      box-shadow: none;
      font-size: 11px;
    }
  }

  &.hidden {
    height: 0px;
  }
}


@media screen and (max-width: $screen-size-medium) {
  #webcamAds {
    flex-direction: column;

    .webcam, .klingon {
      width: 100%;
      margin: 8px 0;
    }

    .webcam {
      flex-direction: column;
      height: auto;
      overflow: hidden;

      .text {
        font-size: 14px;
        text-align: center;
        width: 100%;
      }

      .btn-webcam-inscription {
        font-size: 14px;
        width: 100%;
      }
    }
  }
  #webcamHeader {
    height: auto;

    .container {
      flex-direction: column;

      .timer-big {
        margin-bottom: 16px;
      }

      .btn {
        margin: 16px auto 0 auto;
      }
    }
  }
}

@media screen and (max-width: $screen-size-small) {
  #webcamHeader {
    position: fixed;
    z-index: 10;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    background-color: rgba(0, 0, 0, .5);
    display: flex;

    .container {
      margin: auto 16px;
      border-radius: $border-radius;
      background-color: darken($color-main, 10);
      position: relative;

      .message-container {
        .sub-message {
          margin-top: 16px;
        }
      }
    }
  }
}
