.topic-preview{
  max-width: 1200px;
  width: 100vw;
  .first-message{
    margin: 24px 0;
    box-shadow: $box-shadow-light;
    border-radius: $border-radius;
    .first-message-label{
      display: block;
      font-size: 15px;
      margin-bottom: 8px;
      font-weight: 600;
      padding: 16px 8px;
      border-radius: $border-radius $border-radius 0 0;
      &.man {
        background-color: rgba($color-gender-man, .15);
      }

      &.woman {
        background-color: rgba($color-gender-woman, .15);
      }

      &.couple {
        background-color: rgba($color-gender-couple, .15);
      }

      &.couple-lesbian {
        background-color: rgba($color-gender-couple-lesbian, .15);
      }

      &.couple-gay {
        background-color: rgba($color-gender-couple-gay, .15);
      }

      &.trav {
        background-color: rgba($color-gender-trav, .15);
      }
    }
    .content{
      padding: 16px 8px;
      border-bottom: 1px solid $color-grey-light;
      font-size: 15px;
      color: $color-text-default;
      line-height: 1.4;
      white-space: pre-line;
      @extend .break-long-text;
      img{
        max-width: 100%;
        max-height: 1000px;
      }
    }
  }
}