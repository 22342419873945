#settings {
  //overflow: hidden;
  min-height: calc(100vh - 174px);

  .settings-title {
    display: block;
    margin-bottom: 16px;
  }

  .settings-sub-title {
    display: block;
    font-size: 16px;
    margin-bottom: 16px;
    font-weight: 500;
    font-size: 16px;
    margin: 20px 0 10px 0;    
  }

  .become-certified {
    padding: 5px;
    border-radius: 5px;
    background: #ffc9d0;
    margin-bottom: 5px;
    display: inline-block;
    color: #982a15;
    font-size: 12px;
    font-style: italic;
}  

  .import-media {
    .container {
      width: 1200px;
    }
  }

  .sidebar {
    border-right: 1px solid $color-grey-light;

    .topline {
      height: 100%;
      width: 100%;
    }
  }

  #become-certified {
    .alert-info {
      margin-bottom: 16px;
      background: lighten($color-primary, 41);
      padding: 16px 24px;
      font-size: .9rem;
      border: 1px solid lighten($color-primary, 15);
      border-radius: $border-radius;
      color: darken($color-primary, 3);
      line-height: 1.35rem;

      i {
        fill: $color-primary;
        margin-right: 8px;
      }
    }

    img {
      border-radius: $border-radius;
      max-width: 200px;
      margin-right: 24px;
    }

    .consign {
      padding: 24px 0;
      display: flex;

      img {
        display: block;
        width: 100%;
        max-width: 250px;
        border-radius: $border-radius;
      }

      .consign-explained {
        ul {
          display: block;

          li {
            font-size: .9rem;
            line-height: 1.2rem;
            color: black;
            padding: 16px 0;
          }
        }
      }
    }
  }

  .main-wrapper {
    margin: 0;
    padding-left: 16px;
    padding-right: 0;

    .settings-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      form{
        height: 100%;
      }

      .mk-editor{
        margin: 8px 0;
      }

      .block {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        border-bottom: 1px solid $color-grey-light;
        margin-bottom: 16px;
        padding-bottom: 16px;
        &:last-child{
          margin-bottom: 0;
          padding-bottom: 0;
        }

        .warning-toggle {
          color: #FFF;
          background-color: $color-orange;
          border: 1px solid $color-orange;
        }

        .small {
          cursor: pointer;
        }

        .content {
          display: flex;
          flex-direction: column;
          width: 100%;
          font-size: 14px;
          overflow: auto;
          .date-abo {
            padding-left: 4px;
          }

          .is-certified {
            margin-bottom: 16px;
            font-size: 16px;
            color: $color-text-medium-2;
            padding: 8px;

            .certified {
              color: $color-action;
            }

            i {
              fill: $color-action;
              width: 13px;
            }
            .img-photo{
              max-width: 250px;
            }
          }

          .subscribed {
            font-size: 16px;
            color: $color-text-medium-2;
            padding: 8px;

            .subscription-type {
              color: $color-action;
            }
          }

          select {
            width: 100%;
            margin-bottom: 8px;

            &:last-child {
              margin-bottom: 0;
            }
          }

          .settings-text {
            width: 100%;
            height: 30px;
          }

          input[type="date"] {
            margin: 4px 0;
          }

          textarea {
            width: 100%;
            height: 80px;
            max-width: 100%;
            resize: vertical;
          }

          .edit-span {
            width: 100%;
            color: $color-grey-medium;
            font-size: 13px;
            line-height: 14px;
            margin: 4px 0;

            &.small-span {
              font-weight: 700;
              color: $color-text-default;
              margin: 4px 0;
            }

            &.span-margin {
              margin-bottom: 4px;
            }
          }

          label {
            display: flex;
            align-items: center;
            flex: 1 0;

            span {
              margin: 0;
            }

            &.label-title {
              width: 100%;
              font-size: 14px;
              font-weight: 600;
              margin: 16px 0 8px 0;
            }
          }

          a {
            color: $color-primary;
            text-decoration: none;
          }

          .medium-span {
            font-weight: 700;
            margin-bottom: 32px;
          }

          .settings-btn-group {
            display: flex;

            .filter {
              overflow: hidden;
              border-radius: $border-radius;
              align-self: flex-end;

              li {
                i {
                  fill: $color-text-default;
                  width: 13px;
                  margin-right: 4px;
                }

                &:first-child {
                  border-right: 1px solid $color-grey-light;

                  &.active {
                    border-right: 1px solid darken($color-primary, 3%);
                  }
                }

                &:hover {
                  color: $color-text-default;
                  background-color: $color-grey-light;

                  i {
                    fill: $color-text-default;
                  }
                }

                &.active {
                  color: #FFF;
                  background-color: $color-primary;

                  i {
                    fill: #FFF;
                  }
                }
              }

              &.go-to-right {
                margin-left: auto;
              }

              &.margin-left {
                margin-left: 16px;
              }
            }

            .small {
              align-self: flex-end;
              cursor: pointer;
            }
          }

          .title-icon {
            display: flex;
            width: 100%;
            justify-content: space-between;
            color: $color-text-default;

            div {
              margin-right: 32px;
            }

            i {
              margin: 0 16px;
            }
          }

          .doubleCheckbox {
            display: flex;
            width: 100%;
            padding: 4px 0;
            border-bottom: 1px solid $color-grey-light;
            padding: 12px 8px;
            padding-right: 0;
            font-size: 12px;

            input {
              margin: 0 16px;
            }

            &:first-child {
              border-top: 1px solid $color-grey-light;
            }
          }

          table {
            width: 100%;

            tr {
              width: 100%;
              background-color: $color-grey-light;

              th {
                vertical-align: middle;
                height: 40px;
                background-color: lighten($color-primary, 30%);
                font-size: 13px;
                color: $color-text-default;
                padding: 4px 8px;

                &:first-child {
                  width: 25%;
                  text-align: left;
                }
              }

              td {
                text-align: center;
                height: 40px;
                vertical-align: middle;
                font-size: 12px;
                color: $color-text-medium-2;
                padding: 4px 8px;

                &:first-child {
                  text-align: left;
                }

                i {
                  padding: 8px;
                  cursor: pointer;

                  &.fa-check {
                    &:hover {
                      color: $color-green;
                    }
                  }

                  &.fa-times {
                    &:hover {
                      color: darken($color-red, 10%);
                    }
                  }
                }

                .user {
                  display: flex;
                  align-items: center;

                  .avatar {
                    width: 32px;
                    height: 32px;
                    margin-right: 8px;
                    display: inline-block;
                  }

                  .username {
                    display: inline-block;
                  }
                }

                .small {
                  cursor: pointer;

                  &:hover {
                    color: $color-primary;
                  }
                }
              }
            }

            &.attachment {
              th {
                &:last-child {
                  text-align: right;
                }
              }

              td {
                &:last-child {
                  text-align: right;
                }

                &:first-child {
                  text-align: center;
                }
              }
            }
          }

          &.blocked {
            .toggle-user-to-block {
              width: 200px;

              .dropdown-content {
                width: 200px;
                padding: 8px;
                height: 300px;
                overflow: auto;

                li {
                  display: flex;
                  list-style-type: none;
                  align-items: center;
                  margin: 8px 0;

                  .avatar {
                    width: 32px;
                    height: 32px;
                    margin-right: 16px;
                  }
                }
              }
            }
          }
        }

        &:first-child {
          margin-top: 0;

          label {
            &:first-child {
              margin-top: 0;
            }
          }
        }

        &:last-of-type {
          border-bottom: 0px;
        }
      }
    }

    .settings-btn-group {
      margin-top: auto;
      padding-top: 16px;
      display: flex;
      justify-content: flex-end;

      .small {
        margin: 0 4px;
      }
    }
  }
}

#acquaintance {
  width: 100%;

  .acquaintance-form, .summary {
    margin: 16px 0;
    display: block;
  }

  .topline {
    border: 1px solid $color-grey-light;
    font-size: 15px;
    color: $color-text-medium-2;
    padding: 16px !important;
    border-radius: $border-radius;
    margin-bottom: 16px;
    line-height: 18px;

    ul {
      margin-top: 16px;

      li {
        padding: 4px 0;
      }
    }
  }

  .activateQuestions {
    margin-bottom: 8px;

    .toggle {
      &.isToggled {
        background-color: darken($color-green, 15);
      }
    }

    &.active {
      color: #FFF;
      background-color: $color-green;
      border: 1px solid $color-green;
    }
  }

  .acquaintance-form {
    display: flex;
    flex-direction: column;

    .block {
      margin: 8px 0;
      display: flex;
      flex-direction: column;

      .category-title{
        margin-bottom: 16px;
        color: $color-text-default;
        font-size: 15px;
        font-weight: bold;
      }

      .custom-checkbox-input {
        margin: 8px 0;

        label {
          color: $color-text-medium-2;
          font-size: 15px;
        }
      }
      .delete-question{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 8px;
        i{
          fill: $color-red;
          cursor: pointer;
        }
      }
      .column{
        &.add-question {
          margin-top: 8px;
          padding-top: 8px;
          border-top: 1px solid $color-grey-light;
          .add-question-label {
            width: 100%;
            font-size: 14px;
            color: $color-text-medium-2;
            margin-bottom: 8px;
          }
        }
      }
    }
  }


  .summary {
    display: flex;
    flex-direction: column;

    h3 {
      font-size: 20px;
      color: $color-text-default;
      margin-bottom: 8px;
    }

    .infos-block {
      padding: 16px;
      margin: 8px 0;
      font-size: 14px;
      font-weight: 700;
      color: #FFF;
      border-radius: $border-radius;

      &.valide {
        background-color: $color-green;
        border: 1px solid darken($color-green, 5%);
      }

      &.not-valide {
        background-color: $color-red;
        border: 1px solid darken($color-red, 5%);
      }
    }

    .row {
      padding: 16px 8px;

      .title {
        flex: 1 50%;
        font-weight: 700;
        font-size: 14px;
      }

      .questions {
        flex: 1 50%;
        color: $color-text-medium-2;
        font-size: 15px;

        .small-italic {
          font-size: 14px;
          color: $color-text-medium-2;
          font-style: italic;
        }

        .added-question {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 8px 0;
          line-height: 18px;

          i {
            fill: $color-red;
            cursor: pointer;
            margin-left: 16px;
            flex-shrink: 0;
            width: 10px;
          }
        }
      }

      &:nth-child(odd) {
        background-color: $color-grey-light;
      }
    }
  }

  .btn-group {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 16px 0;

    .primary {
      margin-left: 8px;
    }
  }
}

@media screen and (max-width: $screen-size-medium) {
  #settings {
    .sidebar {
      border-right: 0px;

      .topline {
        overflow-x: auto;
        margin-bottom: 16px;

        ul {
          flex-wrap: nowrap;

          li {
            width: auto;
            flex-grow: 1;
            flex-shrink: 0;
            color: $color-text-medium-2;
            padding: 8px;

            &:last-child {
              padding-right: 0px;
            }
          }
        }
      }
    }

    .container {
      padding: 0;

      .card {
        margin-top: 0;
        min-height: calc(100vh - 162px);
      }
    }

    .main-wrapper {
      padding: 0;

      .settings-wrapper {
        .block {
          flex-direction: column;

          .settings-title {
            margin-bottom: 8px;
          }

          .content {
            .doubleCheckbox {
              flex-wrap: wrap;

              label {
                width: 100%;
                min-width: 200px;
              }

              .settings-btn-group {
                ul {
                  width: 100%;
                }
              }
            }

            &.blocked {
              .toggle-user-to-block {
                .dropdown-content {
                  width: 100%;
                  height: 30%;
                  max-height: 100%;
                  padding: 0;
                  top: unset;

                  .users-container {
                    height: 100%;
                    max-height: calc(100% - 40px);
                    overflow: auto;

                    li {
                      flex-shrink: 0;
                      padding: 8px;
                    }
                  }

                  .close-dropdown {
                    flex-shrink: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  #acquaintance {
    .main-wrapper {
      .acquaintance-form {
        .block {
          .row {
            &.add-question {
              input {
                flex-grow: 1;
              }
            }
          }
        }
      }

      .summary {
        .row {
          flex-direction: column;

          .title {
            margin-bottom: 8px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $screen-size-small) {
  #become-certified {
    .consign {
      flex-direction: column;
      align-items: center;
    }
  }
}