.survey-create{
  max-width: 600px;
  width: 100vw;
  .modal-topline{
    .question-label{
      display: block;
      margin-bottom: 8px;
      font-size: 15px;
    }
  }
  .modal-body{
    .choice{
      display: flex;
      align-items: flex-start;
      width: 100%;
      padding: 8px 0;
      .choice-handler{
        display: flex;
        align-items: flex-start;
        margin-right: 16px;
        padding: 8px;
        cursor: pointer;
        .icon-minimize{
          fill: $color-red;
        }
        .icon-plus{
          fill: $color-primary;
        }
      }
      .custom-text-input, .choice-name{
        font-size: 15px;
        line-height: 1.2;
        color: $color-text-default;
        width: 100%;
        @extend .break-long-text;
      }
    }
  }
}