.btn {
  //font-size: 15px;
  //line-height: 1em;
  //display: inline-block;
  //font-weight: bold;
  //padding: 16px 32px;
  //border: 1px solid $color-grey-light;
  //cursor: pointer;
  //text-align: center;
  //text-decoration: none;
  //background-color: #FFF;
  //color: $color-text-default;
  //outline: none;

  border: 0;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  margin: 0;
  min-width: 36px;
  outline: 0;
  text-decoration: none;
  @include overflow-ellipsis();
  vertical-align: middle;
  border-radius: $border-radius;
  box-shadow: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 34px;
  padding: 0 24px;
  background-color: white;
  user-select: none;
  border: 1px solid $color-grey-light;

  .name{
    text-decoration: none;
  }



  .badge{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
    height: 25px;
    min-width: 25px;
  }

  .btn-icon-left {
    margin-right: 8px;
  }

  .btn-icon-right {
    margin-left: 8px;
  }

  .btn-badge-right {
    margin-left: 8px;
  }

  &.small {
    font-size: 12px;
    //padding: 8px 16px;
    padding: 0 8px;
    text-transform: none;
  }

  &.medium {
    font-size: 14px;
    //padding: 16px 32px;
    height: 46px;
    padding: 0 16px;
    text-transform: none;
  }

  &.large {
    height: 56px;
    font-size: 18px;
    padding: 20px 40px;
  }

  &.full {
    width: 100%;
    text-align: center;
  }

  &.no-shadow {
    box-shadow: none;
  }

  &.transparent {
    color: $color-text-default;
    background: rgba(241, 241, 241, 0.85);
    border-color: transparent;
  }

  &.primary {
    color: $color-primary;
    background: #FFFFFF;
    border-color: $color-primary;
    transition: all 0.3s;

    i {
      fill: $color-primary;
    }
    &.active, &:active, &:hover{
      background-color: $color-primary;
      color: #FFF;
      i {
        fill: #FFF;
      }
    }
  }

  &.primary-reverse {
    //color: #FFFFFF;
    //background: $color-primary;
    //border-color: $color-primary;
    //background: #e8f0fe;
    background: lighten($color-primary, 41);
    border: 0;
    color: darken($color-primary, 3);
    transition: box-shadow 0.3s;
    i {
      //fill: #FFF;
      fill: $color-primary;
    }

    &:hover {
      box-shadow: inset 0px 0px 0px 2px #136df3;
    }
  }

  &.cancel {
    color: $color-red;
    background: #FFF;
    border-color: $color-red;

    i {
      fill: $color-red;
    }

    &:hover {
      color: $color-red;
    }
  }

  &.cancel-reverse {
    //color: #FFF;
    //background: $color-red;
    //border-color: $color-red;
    //
    //i {
    //  fill: #FFF;
    //}

    background: lighten($color-red, 30);
    border: 0;
    color: darken($color-red, 3);
    i {
      fill: $color-red;
    }
  }

  &.white {
    color: #FFF;
    border: 1px solid #FFF;
    background-color: transparent;

    i {
      fill: #FFF;
    }
  }

  &.validate-reverse {
    background-color: $color-green;
    color: #FFF;
  }
  &.m-24{
    margin: 24px;
  }
  &.p-24{
    padding: 24px;
  }
  &.purple {
    a {
      color: #FFF;
      text-decoration: none;
    }

    font-size: 12px;
    background-color: $color-purple;
    color: #fff;
    border: 1px solid $color-purple;
    margin-top: 8px;
    font-size: 12px;
    padding: 4px 8px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      color: #FFF !important;
    }
  }

  &.purple-reverse {
    background-color: $color-purple;
    color: #FFF;
  }

  &.forbid {
    background-color: $color-grey-light;
    color: $color-text-medium-2;
    cursor: not-allowed;

    &:hover {
      background-color: $color-grey-light;
      color: $color-text-medium-2;
      cursor: not-allowed;
    }
  }

  &.grey {
    background-color: $color-grey-light;
    border-color: $color-grey-light;
    color: $color-text-medium-2;
  }

  &.grey-outline{
    background-color: #FFF;
    border-color: $color-grey-light;
    color: $color-text-medium-2;
  }

  &.edit {
    background-color: darken($color-text-grey-medium, 10);
    color: #FFF;
    border-color: darken($color-text-grey-medium, 10);

    i {
      fill: #FFF;
    }
  }

  &.main {
    color: $color-main;
    background: #FFFFFF;
  }

  &.blank {
    color: $color-primary;
    border: 1px solid $color-primary;
  }

  &.unactive {
    //border-color: lighten($color-primary, 20);
    //background-color: lighten($color-primary, 20);
    opacity: .7;
    pointer-events: none;
    cursor: default;
  }

  &:visited {
    //color: inherit;
  }
}

.menu-nobtn{
  font-size: 14px;
  width: 100%;
  padding: 8px 16px;
  text-decoration: none;
  color: #6F6F6F;
  cursor: pointer;
}

.button-group {
  display: flex;
  justify-content: center;
  border: 1px solid $color-primary;
  border-radius: $border-radius;
  overflow: hidden;
  max-width: 100%;

  .btn {
    //padding: 8px 12px;
    margin: 0 !important;
    border-radius: 0;
    box-shadow: unset;
    height: 100%;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    border: 0;

    &:hover {
      background-color: $color-grey-light;
    }

    &.active {
      background: lighten($color-primary, 38);
      border: 0;
      color: $color-primary;
      i {
        fill: $color-primary;
      }
    }
  }
}

.btn-rounded {
  @extend .btn;
  border-radius: 100px;
}

.btn-rounded-corner {
  @extend .btn;
  border-radius: $border-radius;
}

.responsive-btn-640 {
  display: none;
}

@media screen and (max-width: $screen-size-medium) {
  .button-group {
    overflow: auto;
    justify-content: flex-start;

    .btn {
      flex-shrink: 0;
      flex-grow: 1;
      width: auto;
    }
  }
  .filter-gender {
    &.forum-style {
      span, li {
        flex-shrink: 0;
      }
    }
  }
}

@media screen and (max-width: $screen-size-small) {
  .responsive-btn-640 {
    display: block;
  }
}