#photoCheck {
  .categorie {
    display: flex;
    flex: 1 1 calc(100% / 2 - 16px);

    i {
      fill: #FFF;
      width: 16px;
    }
  }
}

@media screen and (max-width: $screen-size-small) {
  #photoCheck {
    .categorie {
      flex: 1 1 100%;
    }
  }
}
