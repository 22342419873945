.upload-summarize {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: darken($color-main, 6);
  height: 30px;
  width: 30px;
  border-radius: $border-radius-rounded;
  padding: 8px;

  .icon-arrow-up {
    fill: #FFF;
    width: 14px;
  }

  .global-progress-bar {
    width: 20px;
    border-radius: 2px;
    height: 3px;
    background-color: $color-grey-light;
    position: relative;
    overflow: hidden;

    .global-progress-bar-inside {
      height: 3px;
      width: 0%;
      background-color: lighten($color-green, 7);
      position: absolute;
      left: 0;
      top: 0;
      transition: width .2s linear;
    }
  }
}

.summarize-container {
  width: 300px;
  max-height: 400px;
  height: 100%;
  overflow: auto;
  background-color: #FFF;

  .upload-summarize-inner-container {
    display: flex;
    background-color: #FFF;
    width: 100%;
    flex-direction: column-reverse;
    justify-content: flex-end;

    .upload-summarize-block {
      display: flex;
      flex-shrink: 0;
      flex-direction: column;
      justify-content: center;
      margin: 4px 0;
      padding: 4px 16px;

      .no-file {
        font-size: 13px;
        color: $color-text-medium-2;
      }

      .upload-summarize-title {
        display: block;
        font-size: 12px;
        line-height: 13px;
        margin-bottom: 4px;
        font-weight: 700;
        flex-shrink: 0;
        @include overflow-ellipsis();
      }

      .progress-bar-small {
        flex-shrink: 0;
        width: 100%;
        height: 4px;
        border-radius: $border-radius;
        background-color: $color-grey-light;
        position: relative;
        overflow: hidden;

        .progress-bar-inside {
          width: 0%;
          background-color: lighten($color-green, 7);
          transition: width .2s linear;
          position: absolute;
          top: 0;
          left: 0;
          height: 4px;
        }
      }

      .file-data {
        margin-top: 4px;
        font-size: 11px;
        color: $color-text-medium-2;
      }
    }
  }
}

.upload-summarize-no-file {
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
  border-bottom: 1px solid $color-grey-light;
  width: 300px;
  max-height: 400px;
  overflow-y: auto;
  background-color: #FFF;

  .upload-summarize-block {
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    justify-content: center;
    margin: 8px 0;

    .no-file {
      font-size: 13px;
      color: $color-text-medium-2;
    }
  }
}

@media screen and (max-width: $screen-size-medium) {
  .summarize-container {
    height: 100%;
    width: 100%;
    max-height: unset;
    overflow: auto;

    .upload-summarize-inner-container {
      width: 100%;
    }
  }
  .upload-summarize-no-file {
    width: 100%;
  }
}
