#blogCheck {
  .block {
    padding: 32px;
    border-radius: $border-radius;
    background-color: $color-grey-light;
    color: $color-text-default;
    font-size: 15px;
    line-height: 18px;
    margin: 8px;
    flex: 1 1 calc(50% - 16px);
    min-width: 200px;

    .avatar {
      margin-right: 16px;
      width: 32px;
      height: 32px;
    }

    .blog-infos {
      margin-bottom: 16px;

      a {
        text-decoration: none;
        color: $color-primary;
      }
    }
  }
}

@media screen and (max-width: $screen-size-small) {
  #blogCheck {
    .block {
      padding: 16px;

      .btn {
        flex: 1 1 calc(100% / 2 - 8px);
      }
    }
  }
}
